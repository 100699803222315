/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-banner {
    padding-top: 50px;
    position: relative;

    &-container {
        margin: 0 auto;
        max-width: @main-max-width;
        padding-bottom: 40px;
        position: relative;
    }

    &-divider {
        border-bottom: 1px solid #d1d1d1;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-title {
        color: #000;
        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 57px;
    }

    &-subtitle {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 15px;
        text-align: justify;
    }

    &-btn-group {
        bottom: 15%;
        left: 0;
        position: absolute;
        right: 0;
    }

    &-btn {
        border-radius: 999999px;
        font-size: 24px;
        color: #1434c2;
        height: 50px;
        letter-spacing: 1px;
        width: 180px;
    }
}

/**
 * @file paddlehub 通用图片 demo样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../components/video-modal/~video.js/dist/video-js.css';
.paddle-video-modal-wrap {
  text-align: center;
}
.paddle-video-modal-wrap:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.paddle-video-modal-wrap .ant-modal-close-icon {
  background-color: rgba(255, 255, 255, 0.2);
}
.video-js {
  height: 100%;
  width: 100%;
}
.video-js + .video-js {
  display: none;
}
.paddle-video-modal {
  display: inline-block;
  max-width: 960px;
  padding: 0;
  top: 0;
  vertical-align: middle;
}
.paddle-video-modal-player-wrap {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.paddle-video-modal-player-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-video-modal-player {
  height: 100%;
  width: 100%;
}
.paddle-video-modal-player * {
  outline: none;
}
.paddle-video-modal .ant-modal-content {
  background-color: transparent;
}
.paddle-video-modal .ant-modal-body {
  padding: 0;
}
.video-demo {
  margin-top: 22px;
  position: relative;
}
.video-demo-right-background {
  background: #fafafa;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 360px;
  z-index: -1;
}
.video-demo-right {
  float: right;
  width: 360px;
}
.video-demo-main {
  margin-right: 360px;
}
.video-demo-result {
  background: #2932E1;
  color: #fff;
  display: inline-block;
  float: right;
  min-width: 219px;
  padding: 17px 12px;
}
.video-demo-result-title {
  font-size: 16px;
  margin-bottom: 14px;
}
.video-demo-result table {
  width: 100%;
}
.video-demo-result td {
  display: table-cell;
  height: 32px;
}
.video-demo-result-row2 {
  width: 120px;
}
.video-demo-result-progress {
  padding: 0 10px;
}
.video-demo-result-progress-inner {
  background: #fff;
  min-width: 1px;
  height: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 0;
}
.video-demo-img {
  background-color: #343434;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 524px;
  position: relative;
}
.video-demo-video-player {
  bottom: 85px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.video-demo-img-input {
  background-color: #000;
  bottom: 0;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  text-align: right;
}
.video-demo-img-input-info {
  color: #ccc;
  float: left;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 38px;
}
.video-demo-img-input-upload {
  float: right;
}
.video-demo-img-gallery {
  background: #ebebeb;
  height: 130px;
  padding: 20px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.video-demo-img-gallery-item-wrap {
  display: inline-block;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.video-demo-img-gallery-item {
  background-color: #c0c0c0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  height: 90px;
  margin: 0 10px;
  cursor: pointer;
  width: 144px;
}
.video-demo-img-gallery-item-selected {
  border: 2px solid #2932e1;
}
.video-demo-json .ant-collapse-header {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 15px 20px;
}
.video-demo-json .ant-collapse-content-box {
  padding: 0;
}
.video-demo-json-body {
  height: 603px;
  overflow: auto;
  padding: 16px;
}
.video-demo-json-list-num-1 .video-demo-json-body {
  height: 603px;
}
.video-demo-json-list-num-2 .video-demo-json-body {
  height: 556px;
}
.video-demo-json-list-num-3 .video-demo-json-body {
  height: 509px;
}
.video-demo-json-list-num-4 .video-demo-json-body {
  height: 462px;
}
.video-demo-json-table {
  width: 100%;
}
.video-demo-json-table td {
  padding: 5px;
}
.video-demo-json-body-content {
  background: inherit;
  display: inline-block;
  margin: 0;
  padding: 0;
  overflow: visible;
}

/**
 * @file paddlehub demo ai写作面板 tag
 * @author FengGuang(fengguang01@baidu.com)
 */

.ai-writing-example-tag {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    margin: 5px 3px;
    padding: 0 12px;

    &:active,
    &.ant-tag-checkable-checked {
        background: rgba(41, 50, 225, .05);
        border: 1px solid #2932e1;
        border-radius: 4px;
        color: #2932e1;
    }
}

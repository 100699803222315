/**
 * @file paddlehub demo 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import '../../components/header/style';
@import '../../components/banner/style';
@import '../../components/footer/style';
@import '../../components/hub-components/vertical-tabs-list/style';
@import '../../components/hub-components/vertical-video-text-card/style';
@import './components/text-card/style';
@import './components/text-card2/style';
@import './components/icon-text-card/style';

.paddle-hub-demo-page {
	padding-top: @header-height-margin;

	&-banner-btn-group {
		margin-top: 30px;
	}

	&-banner-btn {
		border-radius: 999999px;
		font-size: 14px;
		margin-right: 20px;
		width: 140px;
	}

	&-banner-selector {
		position: absolute;
		right: 0;
		top: 18px;
		width: 140px;
	}

	&-content {
		padding-bottom: 20px;
		max-width: @main-max-width;
		margin: 0 auto;
	}

	&-context-h2 {
		color: #000;
		font-size: 30px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 45px;
		min-height: 45px;
		margin-top: 54px;
		text-align: center;
	}

	&-context-h3 {
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		line-height: 21px;
		min-height: 21px;
		margin-top: 10px;
		margin-bottom: 30px;
		text-align: center;
	}

	&-h2 {
		color: #000;
		font-size: 30px;
		font-weight: 400;
		margin-top: 54px;
		text-align: center;
	}

	&-h3 {
		color: #000;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		margin-top: 10px;
		opacity: 0.8;
		text-align: center;
	}
}

@primary-color: #2932E1;
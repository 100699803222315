/**
 * @file paddlehub 通用图片 demo样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../components/video-modal/style';

.old-recovery-demo-tabs {
    .ant-tabs-nav-wrap {
        justify-content: center;
    }
}

.old-recovery-demo {
    margin-top: 22px;
    position: relative;

    &-right-background {
        background: #fafafa;
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 360px;
        z-index: -1;
    }

    &-right {
        float: right;
        width: 360px;
    }

    &-main {
        margin-right: 360px;
    }

    &-result {
        background: @primary-color;
        color: #fff;
        display: inline-block;
        float: right;
        min-width: 219px;
        padding: 17px 12px;

        &-title {
            font-size: 16px;
            margin-bottom: 14px;
        }

        table {
            width: 100%;
        }

        td {
            display: table-cell;
            height: 32px;
        }

        &-row1 {
        }

        &-row2 {
            width: 120px;
        }

        &-row3 {
        }

        &-progress {
            padding: 0 10px;

            &-inner {
                background: #fff;
                min-width: 1px;
                height: 4px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                width: 0;
            }
        }
    }

    &-img {
        background-color: #343434;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 524px;
        position: relative;

        &-uploadimgerr {
            display: inline-block;
            vertical-align: center;

            &-wrap {
                bottom: 0;
                color: #fff;
                left: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                }
            }

            &-icon {
                background-image: url('./image/imgicon.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                height: 80px;
                margin-bottom: 20px;
            }

            &-text {
                color: #fff;
                font-size: 18px;
                text-align: center;
            }
        }

        &-tag {
            background: rgba(0, 0, 0, .8);
            border-radius: 4px;
            color: #fff;
            padding: 5px 10px;
            position: absolute;
            top: 0;

            &-left {
                left: 0;
            }

            &-right {
                right: 0;
            }
        }

        &-center-line {
            bottom: 0;
            cursor: move;
            left: 50%;
            padding: 0 5px;
            margin-left: -7px;
            position: absolute;
            top: 0;
            width: 13px;

            &:after {
                background: rgba(5, 118, 237, .8);
                content: '';
                display: block;
                height: 100%;
            }

            &-handicon {
                background: rgb(5, 118, 237);
                border: 2px solid rgba(255, 255, 255, .8);
                border-radius: 999999px;
                color: #fff;
                font-weight: 600;
                font-size: 16px;
                height: 24px;
                left: -6px;
                position: absolute;
                top: 70%;
                width: 24px;

                &:before,
                &:after {
                    border-radius: 2px;
                    content: '';
                    display: inline-block;
                    height: 6px;
                    position: absolute;
                    width: 6px;
                }

                &:before {
                    border-bottom: 2px solid #fff;
                    border-left: 2px solid #fff;
                    left: 4px;
                    top: 7px;
                    transform: rotate(45deg);
                }

                &:after {
                    border-bottom: 2px solid #fff;
                    border-right: 2px solid #fff;
                    right: 4px;
                    top: 7px;
                    transform: rotate(-45deg);
                }
            }
        }

        &-right-wrap {
            bottom: 0;
            left: 50%;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-right {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0;
            width: 840px;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-loading-wrap {
            bottom: 0;
            left: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
        }

        @keyframes old-recovery-demo-img-loading-move {
            0% {
                transform: translateY(-100%);
            }
            100% {
                transform: translateY(200%);
            }
        }

        &-loading {
            animation: old-recovery-demo-img-loading-move 2s linear 0s infinite normal;
            background-image: url(./image/loading.png);
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(5, 118, 237, .3));
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-bottom: 1px solid #60adff;
            height: 50%;
            transform: translateY(-100%);
        }
    }

    &-video-player {
        bottom: 85px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &-img-input {
        align-items: center;
        //background-color: rgba(0, 0, 0, .5);
        background-color: #000;
        bottom: 0;
        display: flex;
        left: 0;
        padding: 20px;
        position: absolute;
        right: 0;

        &-info {
            color: #ccc;
            flex: auto;
            font-size: 11.8px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.5;
        }

        &-upload {
            flex: none;
        }
    }

    &-img-gallery {
        background: #ebebeb;
        height: 130px;
        padding: 20px 10px;
        overflow-x: auto;
        overflow-y: hidden;

        &-item-wrap {
            display: inline-block;
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

        &-item {
            background-color: #c0c0c0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: inline-block;
            height: 90px;
            margin: 0 10px;
            cursor: pointer;
            width: 144px;
        }

        &-item-selected {
            border: 2px solid #2932e1;
        }
    }


    &-collapse {
        .ant-collapse-header {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            padding: 15px 20px;
        }

        .ant-collapse-content-box {
            padding: 0;
        }

        &-body {
            height: 550px;
            overflow: auto;
            padding: 16px;
        }

        &-table {
            width: 100%;

            td {
                padding: 5px;
            }
        }

        &-body-content {
            background: inherit;
            display: inline-block;
            margin: 0;
            padding: 0;
            overflow: visible;
        }
    }
}

@primary-color: #2932E1;
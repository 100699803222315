.p-x-ai-header-intro {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  margin-bottom: 60px;
  border-bottom: 1px solid #D1D1D1;
}
.p-x-ai-header-intro::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-header-intro-title {
  height: 57px;
  font-family: PingFangSC-Medium;
  font-size: 38px;
  color: #000000;
  letter-spacing: 0;
  line-height: 57px;
  font-weight: 500;
  margin-bottom: 15px;
}
.p-x-ai-header-intro-desc {
  height: 52px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #575757;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 24px;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List {
  margin-bottom: 40px;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .ant-btn-primary:focus {
  color: #FFFFFF;
  background: #1823BA !important;
  border-color: #1823BA !important;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .p-x-ai-header-intro-btn {
  margin-right: 16px;
  background: #2932E1;
  width: 120px;
  height: 40px;
  border-radius: 20px;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .p-x-ai-header-intro-btn span {
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .p-x-ai-header-intro-btn:hover {
  background: #5156ED;
  border: 1px solid #5156ed;
  border-radius: 20px;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .p-x-ai-header-intro-btn:disabled {
  background: #C5D0FF;
  border: none;
}
.p-x-ai-header-intro .p-x-ai-header-intro-btn-List .p-x-ai-header-intro-btn a {
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 16px;
  font-weight: 500;
}

/**
 * @file popular SIG Detail
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */

@import '../../../style/global';
@import '../../../components/header/style';
@import '../../../components/footer/style';

.PP-SIG-popular-wrapper {
    width: 1200px;
    margin: auto;
    .PP-SIG-popular-top-card {
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 60px;
        .top-card-title {
            font-family: PingFangSC-Medium;
            font-size: 38px;
            color: #000;
            letter-spacing: 0;
            line-height: 57px;
            font-weight: 500;
            text-align: left;
            margin-top: 50px;
            margin-bottom: 15px;
        }
        .top-card-desc {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #575757;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 40px;
        }
    }
    .cooperate-content-wrapper {
        margin-bottom: 70px;
        .cooperate-content-title {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #000;
            font-weight: 600;
            margin-bottom: 30px;
        }
        .cooperate-content-item {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #000;
            letter-spacing: 0;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 30px;
            .trans-content-color {
                color: #000;
            }
            .trans-content-color:hover {
                color: #2932e1;
            }
        }
        .cooperate-content-item::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #2932e1;
            margin-right: 20px;
            position: relative;
            bottom: 1px;
        }
    }
    .join-way-wrapper {
        margin-bottom: 70px;
        .join-way-title {
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #000;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: 30px;
        }
        .join-way-content {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #000;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 400;
            margin-right: 5px;
        }
        .join-way-link {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #2932e1;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
    .group-member-wrapper {
        .group-member-title {
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #000;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: 45px;
        }
        .group-member-sub-title {
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #666;
            letter-spacing: 0;
            font-weight: 400;
            margin-bottom: 45px;
        }
        .member-info-card-wrapper {
            overflow: hidden;
            margin-bottom: 45px;
            .member-info-card {
                width: 240px;
                float: left;
                margin-bottom: 45px;
                .member-avator {
                    width: 100px;
                    height: 100px;
                    display: block;
                    border-radius: 50%;
                    margin: auto;
                    margin-bottom: 16px;
                }
                .member-name {
                    font-family: PingFangSC-Regular;
                    font-size: 16px;
                    color: #333;
                    letter-spacing: 0;
                    font-weight: 400;
                    text-align: center;
                }
            }
        }
    }
}
@primary-color: #2932E1;
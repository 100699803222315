/**
 * @file 固钉组件样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-affix-range {
    overflow-y: hidden;
    transform: translateZ(0);

    &-top {
        top: 0;
    }

    &-bottom {
        bottom: 0;
        position: absolute;
    }

    &-fixed {
        position: fixed;
    }

    &:hover {
        overflow-y: auto;
    }

    &-wrap {
        height: 100%;
        width: 100%;
    }
}

/**
 * @file 文字卡片的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
@import '../../../../components/vertical-ellipsis/style.css';

.paddle-icon-text-card-group {
	margin-top: 24px;
}

.paddle-icon-text-card {
	border: 1px solid #d8d8d8;
	border-radius: 16px;
	display: flex;
	padding: 24px 24px 16px 24px;
	position: relative;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover {
		box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, .05);
	}

	&-icon-wrap {
		flex: none;
		width: 40px;
	}
	&-icon {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		display: block;
		width: 32px;
		height: 32px;
	}

	&-info {
		flex: auto;
	}

	&-title {
		color: #000;
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 28px;
	}

	&-text {
		color: #000;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 24px;
		margin-top: 10px;
		position: relative;
		word-wrap: break-word;
	}

	&-tag {
		height: 21px;
	}

	&-tag-group {
		color: #2931e2;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 21px;
		margin-top: 10px;
	}
}

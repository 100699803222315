/**
 * @file paddle hub 列表页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
html,
body {
  min-width: 1400px;
  margin: 0;
  padding: 0;
}
.page {
  font-size: 14px;
  font-weight: 400;
}
.paddle-context-wrap {
  position: relative;
}
.paddle-context-link-more {
  bottom: 0;
  position: absolute;
  right: 0;
}
.paddle-context-link-more a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.paddle-context-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  margin-top: 54px;
}
.paddle-context-h3 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 15px;
}
.paddle-context-h3 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-context-h4 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}
.paddle-context-h4 a {
  color: #2932E1;
  margin-left: 8px;
}
.clear-both:after {
  content: '';
  display: block;
  line-height: 0;
  clear: both;
}
.link-button {
  border: none;
  display: inline;
  margin: 0;
  font-weight: 500;
  padding: 0;
  color: #2932E1;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.link-button:active,
.link-button:hover {
  text-decoration: none;
  outline: 0;
}
.link-button:hover {
  color: #515eed;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-head-search-ac {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 300px;
}
.paddle-head-search-ac .ant-input-prefix {
  color: #2932E1;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 0px;
}
.paddle-head-search-ac.ant-select-open {
  width: 800px;
}
.paddle-head-search-ac .ant-input {
  height: 22px;
  line-height: 22px;
}
.paddle-head-search-ac .ant-input-affix-wrapper {
  margin-bottom: 5px;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless .ant-input-prefix {
  color: #fff;
}
.paddle-head-search-ac .ant-input-affix-wrapper-focused {
  border-radius: 0 !important;
}
.paddle-head-search-ac .ant-input-borderless {
  color: #fff;
}
.paddle-head-search-ac .ant-input-borderless::-webkit-input-placeholder,
.paddle-head-search-ac .ant-input-borderless::placeholder {
  color: rgba(254, 253, 253, 0.5);
}
.paddle-head-search-ac-overlay {
  padding: 0 0 4px 0;
}
.paddle-head-search-ac-overlay-ellipsis {
  display: inline-block;
  flex: none;
  overflow: hidden;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.paddle-head-search-ac-overlay .ant-select-item {
  padding: 5px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item,
.paddle-head-search-ac-overlay .ant-select-item a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay .ant-select-item-group {
  padding: 23px 40px 8px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item-group,
.paddle-head-search-ac-overlay .ant-select-item-group a {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay-more {
  color: #2932E1;
  padding: 12px 40px;
}
.paddle-head-search-ac-overlay-divider {
  border-top: 1px solid #dadcdf;
  margin: 24px 40px 0 40px;
}
.paddle-head-search-ac-overlay-item {
  align-items: center;
  display: flex;
}
.paddle-head-search-ac-overlay-item-title {
  color: #666;
  display: inline-block;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-head-search-ac-overlay-item-title em {
  color: #000;
  font-weight: 700;
}
.paddle-head-search-ac-overlay-item-divider {
  border-left: 1px solid #dadcdf;
  display: inline-block;
  height: 14px;
}
.paddle-head-search-ac-overlay-item-type {
  padding-left: 18px;
}
.paddle-head-search-ac-overlay-loading-wrap {
  margin: 24px 40px;
}
.paddle-header-menu-popup .ant-select-dropdown {
  border-radius: 0;
}
.paddle-head-search-ac-overlay-type2 .ant-select-item-group,
.paddle-head-search-ac-overlay-type2 .ant-select-item-group a {
  color: #000;
}
.paddle-header-wrap {
  font-size: 14px;
  font-weight: 400;
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  z-index: 999;
}
.paddle-header {
  font-weight: 400;
  height: 60px;
  margin: 0 30px;
  min-width: 1200px;
  overflow: hidden;
}
.paddle-header-background {
  background-color: #1527c2;
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
  color: #fff;
}
.paddle-header .ant-menu-item-selected a {
  color: #fff;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 60px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  width: 100%;
}
.paddle-header-logo {
  margin-top: 13px;
  width: 96px;
}
.paddle-header-menu {
  display: inline-block;
  padding: 16px 0 16px 33px;
  vertical-align: top;
  min-width: 697px;
}
.paddle-header-right {
  float: right;
  height: 60px;
}
.paddle-header-right-feedback {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}
.paddle-header-right-feedback-close {
  position: relative;
  top: 7px;
  left: calc(100% - 22px);
}
.paddle-header-right-feedback-close .feedback-close-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  background-image: url('../../images/headerfeedback/icon-close.png');
  background-size: cover;
}
.paddle-header-right-feedback-button {
  cursor: pointer;
  position: absolute;
  width: 92px;
  height: 32px;
  left: 10%;
  top: 50%;
  background-image: url('../../images/headerfeedback/button.png');
  background-size: cover;
}
.paddle-header-right-feedback-button span {
  margin-left: 10px;
  text-align: center;
  font-size: 12.6px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 40px;
}
.paddle-header-right-feedback-popover .ant-popover-content {
  position: relative;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin-top: 4px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-arrow {
  top: -4px;
  border: #2932e1;
  background-color: #2932e1;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner {
  width: 314px;
  height: 116px;
  background-image: url('../../images/headerfeedback/bg.png');
  background-size: cover;
  border-radius: 0px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  border-bottom: 0px;
}
.paddle-header-right-feedback-icon {
  width: 40px;
  height: 40px;
}
.paddle-header-right-language {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
}
.paddle-header-right-language span {
  margin: 0 5px;
  cursor: pointer;
}
.paddle-header-right-language span:hover {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-zh {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-en {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
}
.paddle-header-search-wrap {
  display: inline-block;
  line-height: 60px;
  margin-right: 10px;
  width: 280px;
  vertical-align: top;
}
.paddle-header-links-menu {
  display: inline-block;
  height: 60px;
  margin: 0 -17px 0 17px;
  padding: 16px 0 16px 0;
  vertical-align: top;
}
.paddle-header .ant-avatar .anticon {
  margin-right: 0;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 14px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu:nth-last-child(1) {
  margin: 0 16px;
  padding: 0;
}
.paddle-header .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline),
.paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-item:hover > a,
.paddle-header .ant-menu-item-active > a,
.paddle-header .ant-menu:not(.ant-menu-inline) > a,
.paddle-header .ant-menu-submenu-open > a,
.paddle-header .ant-menu-submenu-active > a,
.paddle-header .ant-menu-submenu-title > a,
.paddle-header .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.paddle-header .ant-menu-item,
.paddle-header .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-item.paddle-header-user-avatar-menu-item,
.paddle-header .ant-menu-submenu.paddle-header-user-avatar-menu-item {
  border-bottom: 3px solid transparent;
  height: 27px;
  margin-top: -2px;
  vertical-align: top;
}
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
}
.paddle-header .ant-menu-item a {
  color: #fff;
}
.paddle-header .ant-menu-item a:hover {
  color: #fff;
}
.paddle-header .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.paddle-header-item-group-list .ant-menu-item,
.paddle-header-item-group-list .ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  height: 27px;
}
.paddle-header-menu-popup {
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 60px;
  z-index: 1499;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item {
  font-weight: 500;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-active {
  background: #f7f7f7;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item,
.paddle-header-menu-popup .ant-menu .ant-menu-submenu {
  margin: 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-item,
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-submenu {
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header-submenu-popup-large {
  max-width: 100%;
  right: 0;
}
.paddle-header-submenu-popup-large .ant-menu {
  display: flex;
  padding: 22px 0 42px 55px;
  text-align: left;
}
.paddle-header-submenu-popup-large .ant-menu > * {
  flex-grow: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group {
  float: left;
  padding: 0 15px;
  width: 225px;
  z-index: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-title {
  border-bottom: 1px solid #e9eaec;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  margin: 0 10px;
  padding: 0;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-list {
  margin-top: 12px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item:hover {
  background: #f7f7f7;
  color: #1434c2;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title {
  width: 315px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-title {
  display: none;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item {
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover),
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover) > a {
  color: #000;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-list {
  margin-top: 0;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-right-icon {
  margin-left: 10px;
  transform: scale(0.65, 0.8);
}
.paddle-header-submenu-popup-large .ant-menu-hidden {
  display: none;
}
.paddle-header-submenu-popup-large-tag {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
.paddle-header-submenu-popup-large-tag.red {
  color: #ff3912;
}
.paddle-header-submenu-popup-large-tag.blue {
  color: #1a73e8;
}
.paddle-header-submenu-popup-large-tag.green {
  color: #00b359;
}
.paddle-header-submenu-popup-large-bg {
  background: #fff;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 21%;
  z-index: 0;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 60px;
  height: 60px;
  margin-left: -2px;
}
.paddle-header-links-link:after {
  display: block;
  content: attr(title);
  font-weight: 500;
  visibility: hidden;
  height: 0;
  margin-bottom: -1px;
  overflow: hidden;
}
@media screen and (max-width: 1260px) {
  .paddle-header {
    margin: 0 auto;
    width: 1400px;
  }
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #000;
  display: block;
  min-height: 403px;
}
.paddle-footer,
.paddle-footer a {
  color: #999;
}
.paddle-footer-container {
  max-width: 1400px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0;
}
.paddle-footer-group {
  width: 191px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  font-size: 18px;
}
.paddle-footer-item-list {
  float: left;
  margin-top: 25px;
}
.paddle-footer-item,
.paddle-footer-item a {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}
.paddle-footer-qr-wrap {
  float: right;
  margin-bottom: -11px;
  margin-right: -55px;
}
.paddle-footer-qr-item {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 180px;
  text-align: center;
}
.paddle-footer-qr-context {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.paddle-footer-qr-context-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-title {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  text-align: center;
  vertical-align: top;
}
.paddle-footer-qr-subtitle {
  font-size: 12px;
}
.paddle-footer-bottom {
  background: #000;
  border-top: 1px solid #242424;
  color: #999;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.paddle-footer .beian {
  margin-left: 100px;
}
.paddle-footer .footer-mark-number {
  margin-left: 80px;
}
.paddle-footer .record-link-style:hover {
  color: rgba(153, 153, 153, 0.8);
}
/**
 * @file paddle hub 列表页菜单样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-menu {
  background: #fff;
  border-right: 0;
  height: 100%;
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  width: 100%;
  z-index: 10;
}
.paddle-hub-menu:hover {
  overflow-y: auto;
}
.paddle-hub-menu .ant-menu {
  border-right: none;
  font-weight: 400;
}
.paddle-hub-menu .ant-menu .ant-menu-item {
  margin: 0;
  padding-left: 10px;
}
.paddle-hub-menu .ant-menu .ant-menu-item:active {
  background: transparent;
}
.paddle-hub-menu .ant-menu .ant-menu-item-selected {
  background: transparent;
  font-weight: 600;
}
.paddle-hub-menu .ant-menu .ant-menu-item-group {
  margin-top: 20px;
}
.paddle-hub-menu .ant-menu .ant-menu-item-group-list .ant-menu-submenu-title {
  padding-left: 10px;
}
.paddle-hub-menu .ant-menu .ant-menu-item-group-title {
  color: rgba(0, 0, 0, 0.3);
  padding-left: 10px;
}
.paddle-hub-menu .ant-menu .ant-menu-item:hover:not(.ant-menu-item-selected),
.paddle-hub-menu .ant-menu .ant-menu-item-active:not(.ant-menu-item-selected),
.paddle-hub-menu .ant-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.paddle-hub-menu .ant-menu .ant-menu-submenu-active,
.paddle-hub-menu .ant-menu .ant-menu-submenu-title:hover {
  color: #000;
}
.paddle-hub-menu .ant-menu-vertical .ant-menu-item::after,
.paddle-hub-menu .ant-menu-vertical-left .ant-menu-item::after,
.paddle-hub-menu .ant-menu-vertical-right .ant-menu-item::after,
.paddle-hub-menu .ant-menu-inline .ant-menu-item::after {
  border-right-width: 2px;
  bottom: 14px;
  left: 0;
  opacity: 0;
  transform: scaleY(0.0001);
  right: unset;
  top: 14px;
}
.paddle-hub-menu .ant-menu-vertical .ant-menu-item-selected.ant-menu-item::after,
.paddle-hub-menu .ant-menu-vertical-left .ant-menu-item-selected.ant-menu-item::after,
.paddle-hub-menu .ant-menu-vertical-right .ant-menu-item-selected.ant-menu-item::after,
.paddle-hub-menu .ant-menu-inline .ant-menu-item-selected.ant-menu-item::after {
  opacity: 1;
  transform: scaleY(1);
}
/**
 * @file 固钉组件样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-affix-range {
  overflow-y: hidden;
  transform: translateZ(0);
}
.paddle-affix-range-top {
  top: 0;
}
.paddle-affix-range-bottom {
  bottom: 0;
  position: absolute;
}
.paddle-affix-range-fixed {
  position: fixed;
}
.paddle-affix-range:hover {
  overflow-y: auto;
}
.paddle-affix-range-wrap {
  height: 100%;
  width: 100%;
}
/**
 * @file 排序tag样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-sorter {
  cursor: pointer;
  display: inline-flex;
  line-height: 32px;
}
.paddle-sorter-icon {
  display: block;
  font-size: 10px;
  margin-left: 4px;
  margin-top: -3px;
}
.paddle-sorter-icon .anticon {
  display: block;
}
.paddle-sorter-icon .anticon + .anticon {
  margin-top: -2px;
}
.paddle-sorter-icon .paddle-sorter-icon-inner {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
}
.paddle-sorter-icon .anticon-caret-up,
.paddle-sorter-icon .anticon-caret-down {
  color: #d6d6d6;
}
.paddle-sorter-icon.paddle-sorter-icon-down .anticon-caret-down {
  color: #999;
}
.paddle-sorter-icon.paddle-sorter-icon-up .anticon-caret-up {
  color: #999;
}
/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-banner {
  padding-top: 50px;
  position: relative;
}
.paddle-banner-container {
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 40px;
  position: relative;
}
.paddle-banner-divider {
  border-bottom: 1px solid #d1d1d1;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-title {
  color: #000;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 80px;
}
.paddle-banner-subtitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 15px;
  text-align: justify;
}
.paddle-banner-btn-group {
  bottom: 15%;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-btn {
  border-radius: 999999px;
  font-size: 24px;
  color: #1434c2;
  height: 50px;
  letter-spacing: 1px;
  width: 180px;
}
/**
 * @file paddle hub 首页模型列表样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-model-list-pagination-wrap {
  margin-top: 30px;
  text-align: center;
}
.paddle-hub-model-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #333;
  display: block;
  margin-bottom: 25px;
  padding: 30px;
  transition: all 0.2s ease;
}
.paddle-hub-model-item:last-of-type {
  margin-bottom: 0;
}
.paddle-hub-model-item:hover {
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
  color: #333;
}
.paddle-hub-model-item-name {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
}
.paddle-hub-model-item-tag {
  color: #ff7c3f;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 4px;
  vertical-align: middle;
}
.paddle-hub-model-item-info {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 15px;
}
.paddle-hub-model-item-category {
  margin-left: 40px;
}
.paddle-hub-model-item-network {
  margin-left: 40px;
}
.paddle-hub-model-item-dataset {
  margin-left: 40px;
}
.paddle-hub-model-item-summary {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
  max-height: 24px;
  overflow: hidden;
  position: relative;
}
.paddle-hub-model-item-summary-ellipsis {
  background: #fff;
  bottom: 0;
  display: inline-block;
  position: absolute;
  right: 0;
}
.paddle-hub-model-item-more-info {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 10px;
  line-height: 22px;
}
.paddle-hub-model-item-more-info > * {
  margin-right: 20px;
}
.paddle-hub-model-item-more-info-recommendation-number {
  color: #000;
  font-weight: 500;
}
/**
 * @file paddle hub 首页模型列表左侧菜单
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-menu-wrap .ant-collapse-ghost > .ant-collapse-item.paddle-hub-menu-panel-switch-wrap > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.paddle-hub-menu-wrap .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 16px;
  padding-right: 12px;
}
.paddle-hub-menu-item {
  margin-bottom: 0;
}
.paddle-hub-menu-item .ant-form-item-control-input {
  min-height: unset;
}
.paddle-hub-menu-item-wrap {
  cursor: pointer;
  outline: none;
  padding: 0 20px 0 0;
}
.paddle-hub-menu-item-title {
  color: #000;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  padding: 13px 0;
}
.paddle-hub-menu-item-title > div {
  flex: auto;
}
.paddle-hub-menu-item-title > div:last-of-type {
  flex: none;
}
.paddle-hub-menu-item-title-icon {
  color: #666;
}
.paddle-hub-menu-item-title-icon .anticon-down {
  transform: scale(1, 0.8);
}
.paddle-hub-menu-select {
  display: block;
}
.paddle-hub-menu-select.ant-select-multiple .ant-select-selection-item {
  border: 1px solid #e0e0e0;
  height: 28px;
  line-height: 24px;
}
.paddle-hub-menu-select.ant-select-multiple .ant-select-selector {
  margin: 0 -5px;
  padding: 0;
}
.paddle-hub-menu-select.ant-select-multiple .ant-select-selector::after {
  content: none;
}
.paddle-hub-menu-select.ant-select-multiple:not(.ant-select-show-search) .ant-select-selection-search {
  display: none;
}
.paddle-hub-menu-select-popup .ant-cascader-menu {
  min-width: 200px;
}
.paddle-hub-menu-select-popup .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  font-weight: 400;
  background-color: transparent;
}
.paddle-hub-menu-select-popup .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #f2f5ff;
}
.paddle-hub-menu-wrap {
  border-right: 1px solid #ebebeb;
}
.paddle-hub-menu-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 24px;
  padding: 0 20px 0 0;
}
.paddle-hub-menu-clear-all {
  cursor: pointer;
  color: #666;
  float: right;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}
.paddle-hub-menu-panel-switch-wrap .ant-collapse-content-box {
  padding-bottom: 0;
  padding-top: 0;
}
.paddle-hub-menu-panel-switch-wrap .ant-collapse-extra {
  margin-top: -7px;
}
/**
 * @file paddle hub 搜索排行榜列表样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-chart {
  border: 1px solid #dadcdf;
  margin-bottom: 20px;
}
.paddle-hub-chart-title {
  background: #f5f5fe;
  color: #0b0504;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 18px 20px;
}
.paddle-hub-chart-body {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  padding: 10px 20px 15px 20px;
  text-align: justify;
}
.paddle-hub-chart-line {
  color: #666;
  margin-top: 4px;
  display: flex;
}
.paddle-hub-chart-line:hover {
  color: #666;
}
.paddle-hub-chart-line > * {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.paddle-hub-chart-line > *:first-of-type {
  flex: none;
  width: 18px;
}
.paddle-hub-chart-line > *:last-of-type {
  flex: none;
  max-width: 60%;
}
.paddle-hub-chart-item-red {
  color: #ea2e2e;
}
.paddle-hub-page {
  padding-top: 0px;
}
.paddle-hub-page-banner-btn-group {
  position: absolute;
  right: 0;
  top: 20px;
}
.paddle-hub-page-banner-btn {
  font-size: 18px;
  font-weight: 600;
}
.paddle-hub-page-container {
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
  min-height: 600px;
  padding-bottom: 120px;
}
.paddle-hub-page-container-left {
  bottom: 120px;
  flex: none;
  left: 0;
  width: 240px;
}
.paddle-hub-page-container-center {
  flex: auto;
}
.paddle-hub-page-container-center-top {
  padding: 0 20px;
}
.paddle-hub-page-container-right {
  flex: none;
  padding-top: 52px;
  width: 240px;
}
.paddle-hub-page-search-auto-complete {
  width: 320px;
}
.paddle-hub-page-search-input {
  border-radius: 999999px;
  width: 320px;
}
.paddle-hub-page-search-input .ant-input-clear-icon {
  margin-right: -12px;
}
.paddle-hub-page-search-input .anticon-search {
  visibility: hidden;
}
.paddle-hub-page-search-input .ant-input-clear-icon-hidden + .anticon-search {
  visibility: visible;
}
.paddle-hub-model-list-wrap {
  padding: 20px;
}
.paddle-hub-sorter {
  margin-left: 30px;
}

.p-x-ai-related-information {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 80px;
    &::after {
        content: "";
        display: block;
        overflow:hidden;
        visibility:hidden;
        height: 0;
        clear: both;
    };

    // 

    .p-x-ai-relevant-information-title {
        height: 42px;
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        margin-bottom: 40px;
    };
    .p-x-ai-relevant-information-content {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        &::after {
            content: "";
            display: block;
            overflow:hidden;
            visibility:hidden;
            height: 0;
            clear: both;
        };
        .p-x-ai-relevant-information-content-module {
            width: 384px;
            min-height: 218px;
            border: 1px solid rgba(228,231,253,1);
            border-radius: 16px;
            margin-right: 24px;
            margin-bottom: 10px;
            &:hover {
                box-shadow: 4px 8px 12px 0px rgba(0,0,0,0.05);
                cursor: pointer;
            };
           
            &:nth-of-type(3) {
                margin-right: 0px;
            };
            &:nth-of-type(6) {
                margin-right: 0px;
            };
            &:nth-of-type(9) {
                margin-right: 0px;
            };
            .content-module-title {
                width: 384px;
                height: 76px;
                padding: 25px 30px 25px 30px;
                box-sizing: border-box;
                border-radius: 16px 16px 0px 0px;
                font-family: PingFangSC-Medium;
                font-size: 20px;
                color: #000000;
                font-weight: 500;
                background-color: #f6f7fe;
                margin-bottom: 20px;
                white-space: nowrap;
                overflow: hidden; 
                text-overflow: ellipsis;
            };
            .content-module-desc {
                height: 72px;
                padding: 0px 30px;
                box-sizing: border-box;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #666666;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 10px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-clamp: 3; 
                box-orient: vertical;
            };
            .content-module-details {
                padding: 0px 30px;
                box-sizing: border-box;
                cursor: pointer;
                height: 20px;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #2932E1;
                letter-spacing: 0;
                line-height: 20px;
                font-weight: 500;
                &:hover {
                    opacity: 0.9;
                };
            };
            .content-module-details-hover:hover{
                opacity: 0.9; 
            }; 
            .content-module-details-disabled:hover {
                cursor: not-allowed;
                color: #C5D0FF ;
            };
        };
        .p-x-ai-relevant-information-content-module-disabled:hover {
            cursor: not-allowed;
        };
    };
    .p-x-ai-relevant-information-consulting {
        height: 24px;
        display: flex;
        justify-content: center;
        
        .consulting-jump {
            cursor: pointer;
            height: 24px;
            display: flex;
            // align-items: center;
            .consulting-jump-name {
                margin-right: 4px;
                font-family: PingFangSC-Medium;
                font-size: 16px;
                color: #2932E1;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 500;
                
            };
            .consulting-arrow {
                display: block;
                width: 16px;
                height: 16px;
                background-size: contain;
                margin-top: 4px;
            };
          
        };
        .consulting-jump-hover:hover{
            opacity: 0.9; 
        }; 
        .consulting-jump-disabled:hover {
            cursor: not-allowed;
            opacity: 0.5;
        };
    };
};
@primary-color: #2932E1;
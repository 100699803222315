/**
 * @file paddlehub 通用图片 demo样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../components/video-modal/~video.js/dist/video-js.css';
.paddle-video-modal-wrap {
  text-align: center;
}
.paddle-video-modal-wrap:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.paddle-video-modal-wrap .ant-modal-close-icon {
  background-color: rgba(255, 255, 255, 0.2);
}
.video-js {
  height: 100%;
  width: 100%;
}
.video-js + .video-js {
  display: none;
}
.paddle-video-modal {
  display: inline-block;
  max-width: 960px;
  padding: 0;
  top: 0;
  vertical-align: middle;
}
.paddle-video-modal-player-wrap {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.paddle-video-modal-player-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-video-modal-player {
  height: 100%;
  width: 100%;
}
.paddle-video-modal-player * {
  outline: none;
}
.paddle-video-modal .ant-modal-content {
  background-color: transparent;
}
.paddle-video-modal .ant-modal-body {
  padding: 0;
}
.old-recovery-demo-tabs .ant-tabs-nav-wrap {
  justify-content: center;
}
.old-recovery-demo {
  margin-top: 22px;
  position: relative;
}
.old-recovery-demo-right-background {
  background: #fafafa;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 360px;
  z-index: -1;
}
.old-recovery-demo-right {
  float: right;
  width: 360px;
}
.old-recovery-demo-main {
  margin-right: 360px;
}
.old-recovery-demo-result {
  background: #2932E1;
  color: #fff;
  display: inline-block;
  float: right;
  min-width: 219px;
  padding: 17px 12px;
}
.old-recovery-demo-result-title {
  font-size: 16px;
  margin-bottom: 14px;
}
.old-recovery-demo-result table {
  width: 100%;
}
.old-recovery-demo-result td {
  display: table-cell;
  height: 32px;
}
.old-recovery-demo-result-row2 {
  width: 120px;
}
.old-recovery-demo-result-progress {
  padding: 0 10px;
}
.old-recovery-demo-result-progress-inner {
  background: #fff;
  min-width: 1px;
  height: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 0;
}
.old-recovery-demo-img {
  background-color: #343434;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 524px;
  position: relative;
}
.old-recovery-demo-img-uploadimgerr {
  display: inline-block;
  vertical-align: center;
}
.old-recovery-demo-img-uploadimgerr-wrap {
  bottom: 0;
  color: #fff;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.old-recovery-demo-img-uploadimgerr-wrap:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.old-recovery-demo-img-uploadimgerr-icon {
  background-image: url('./image/imgicon.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  margin-bottom: 20px;
}
.old-recovery-demo-img-uploadimgerr-text {
  color: #fff;
  font-size: 18px;
  text-align: center;
}
.old-recovery-demo-img-tag {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  top: 0;
}
.old-recovery-demo-img-tag-left {
  left: 0;
}
.old-recovery-demo-img-tag-right {
  right: 0;
}
.old-recovery-demo-img-center-line {
  bottom: 0;
  cursor: move;
  left: 50%;
  padding: 0 5px;
  margin-left: -7px;
  position: absolute;
  top: 0;
  width: 13px;
}
.old-recovery-demo-img-center-line:after {
  background: rgba(5, 118, 237, 0.8);
  content: '';
  display: block;
  height: 100%;
}
.old-recovery-demo-img-center-line-handicon {
  background: #0576ed;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 999999px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  height: 24px;
  left: -6px;
  position: absolute;
  top: 70%;
  width: 24px;
}
.old-recovery-demo-img-center-line-handicon:before,
.old-recovery-demo-img-center-line-handicon:after {
  border-radius: 2px;
  content: '';
  display: inline-block;
  height: 6px;
  position: absolute;
  width: 6px;
}
.old-recovery-demo-img-center-line-handicon:before {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  left: 4px;
  top: 7px;
  transform: rotate(45deg);
}
.old-recovery-demo-img-center-line-handicon:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  right: 4px;
  top: 7px;
  transform: rotate(-45deg);
}
.old-recovery-demo-img-right-wrap {
  bottom: 0;
  left: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.old-recovery-demo-img-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  width: 840px;
  position: absolute;
  right: 0;
  top: 0;
}
.old-recovery-demo-img-loading-wrap {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
@keyframes old-recovery-demo-img-loading-move {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(200%);
  }
}
.old-recovery-demo-img-loading {
  animation: old-recovery-demo-img-loading-move 2s linear 0s infinite normal;
  background-image: url(./image/loading.png);
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(5, 118, 237, 0.3));
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom: 1px solid #60adff;
  height: 50%;
  transform: translateY(-100%);
}
.old-recovery-demo-video-player {
  bottom: 85px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.old-recovery-demo-img-input {
  align-items: center;
  background-color: #000;
  bottom: 0;
  display: flex;
  left: 0;
  padding: 20px;
  position: absolute;
  right: 0;
}
.old-recovery-demo-img-input-info {
  color: #ccc;
  flex: auto;
  font-size: 11.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
}
.old-recovery-demo-img-input-upload {
  flex: none;
}
.old-recovery-demo-img-gallery {
  background: #ebebeb;
  height: 130px;
  padding: 20px 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.old-recovery-demo-img-gallery-item-wrap {
  display: inline-block;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.old-recovery-demo-img-gallery-item {
  background-color: #c0c0c0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  height: 90px;
  margin: 0 10px;
  cursor: pointer;
  width: 144px;
}
.old-recovery-demo-img-gallery-item-selected {
  border: 2px solid #2932e1;
}
.old-recovery-demo-collapse .ant-collapse-header {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 15px 20px;
}
.old-recovery-demo-collapse .ant-collapse-content-box {
  padding: 0;
}
.old-recovery-demo-collapse-body {
  height: 550px;
  overflow: auto;
  padding: 16px;
}
.old-recovery-demo-collapse-table {
  width: 100%;
}
.old-recovery-demo-collapse-table td {
  padding: 5px;
}
.old-recovery-demo-collapse-body-content {
  background: inherit;
  display: inline-block;
  margin: 0;
  padding: 0;
  overflow: visible;
}

.voice_usedToIntroduce {
    width: 1200px;
    margin: 0 auto;
    margin-top: 81px;
    overflow: hidden;
    background: #FFFFFF;
    .voice_usedToIntroduce_title {
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        margin-bottom: 40px;
    };
    .voice_usedToIntroduce_context_version {
        display: flex;
        margin-bottom: 35px;
        align-items: center;
       .voice_usedToIntroduce_version_context_title {
        // width: 72px;
        height: 20px;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 20px;
        font-weight: 500;
        margin-right: 68px;
       
       };
    };
    .voice_usedToIntroduce_version_context_title_context {
        width: 1060px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        padding: 20px;
        box-sizing: border-box;
        // overflow: auto;
        border-radius: 8px;
        ul {
            width: 1020px;
            // height: 62px;
            background: #FAFAFA;
            border-radius: 4px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding:20px 30px;
            box-sizing: border-box;
            li {
                list-style-type: none;
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #000000;
                letter-spacing: 0;
                font-weight: 600;
                line-height: 30px;
                display: flex;
                margin-right: 102px;
                margin-bottom: 6px;
                 div {
                    width: 6px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    position: relative;
                    top: 4px;
                    span {
                        width: 6px;
                        height: 6px;
                        background: #2932E1;
                        border-radius: 50%;
                    }
                 };
               
            };
            li:nth-last-of-type(1) {
                margin-right: 0;
            }
        };
    };
    .voice_usedToIntroduce_context {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        align-items: center;
        .voice_usedToIntroduce_context_title {
            height: 20px;
            font-family: PingFangSC-Medium;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
            text-align: right;
            line-height: 20px;
            font-weight: 500;
           
        };
       
        .voice_usedToIntroduce_context_title_context {
            width: 1060px;
            height: 350px;
            background: #FFFFFF;
            border: 1px solid #E0E0E0;
            padding: 20px 20px 0 20px;
            box-sizing: border-box;
            border-radius: 8px;
            .voice_usedToIntroduce_context_title_context_top {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                div {
                    height: 22px;
                    font-family: PingFangSC-Medium;
                    font-size: 14px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 500; 
                    
                };
                .voice_usedToIntroduce_context_title_context_top__box {
                    position: relative;
                    width: 14px;
                    height: 14px;
                    .voice_usedToIntroduce_context_title_context_top_box_img {
                        width: 100%;
                        background: url("../../image/复制默认态.svg") no-repeat;
                        background-size:contain; 
                        .voice_usedToIntroduce_context_title_context_top_img_hint {
                            width: 72px;
                            height: 34px;
                            background: #000000;
                            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
                            // height: 20px;
                            font-family: PingFangSC-Regular;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 36px;
                            font-weight: 400;
                            position: absolute;
                            top: -40px;
                            left: -40px;
                            border-radius: 6px;
                            text-align: center;
                            border:1px solid rgba(0,0,0.2);
                        };
                        .voice_usedToIntroduce_context_title_context_top_img_hint::before {
                            content: "";
                            width: 0;
                            height: 0;
                            position: absolute;
                            top: 33px;
                            left: 32px;
                            border: 3px solid #000000;
                            border-style: solid dashed dashed;
                            border-color: #000000 transparent transparent ;
                        };
                    };
                }
               
                .voice_usedToIntroduce_context_title_context_top_img:hover {
                    width: 14px;
                    height: 14px;
                    background: url("../../image/复制hover态.svg") no-repeat;
                    background-size:cover;  
                };
            };
        };
        .usedToIntroduce_ulBox {
            width: 1020px;
            height: 275px;
            background: #FAFAFA;
            padding-right: 8px;
            box-sizing: border-box;
        }
        ul {
            width: 1012px;
            height: 275px;
            background: #FAFAFA;
            border-radius: 4px;
            // overflow: auto;
            overflow-y: auto;
            overflow-x: hidden;
            padding:16px 0 0 20px;
            box-sizing: border-box;
            // margin-right: 8px;
            //  margin-right: 8px;
        };
        // ul::before {
        //     margin-right: 8px;
        // }
        /*滚动条的小边角*/
        ul::-webkit-scrollbar-corner {
            background: transparent;
            // margin-right: 8px;
        };

        /*滚动条里面轨道*/
        ul ::-webkit-scrollbar-track {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
            // margin-right: 8px;
            // right: 8px;
        };
        
        /*滚动条滑块*/
        ul::-webkit-scrollbar-thumb{
            border-radius: 3px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #FAFAFA;
            // margin-right: 8px;
            // right: 8px;
        };

        /*滚动条高宽度*/
        ul::-webkit-scrollbar{
            width: 6px;
            height: 4px;
            // margin-right: 8px;
        } ;
        ul::-webkit-scrollbar-track {
            margin-right: 8px;
        }
      
        li {
            list-style-type: none;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 400;
        } ;
    };
};
@primary-color: #2932E1;
/**
 * @file paddlehub demo ai写作 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import './components/show-pane/style';

.ai-writing {
    border: 1px solid #d8d8d8;
    margin-top: 40px;

    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
        text-align: left;
    }

    .ant-tabs-content {
        padding: 30px;
    }

    .ant-tabs-left {
        > .ant-tabs-content-holder >,
        > div > .ant-tabs-content-holder {
            > .ant-tabs-content > .ant-tabs-tabpane {
                padding-left: 0;
            }
        }
    }

    .ant-tabs-nav {
        min-width: 180px;
    }

    .ant-tabs-ink-bar {
        display: none !important;
    }

    .ant-tabs-nav {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .ant-tabs-tab {
        font-size: 20px;
    }

    .ant-tabs-nav .ant-tabs-tab {
        margin-bottom: 0;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
        background: @primary-color;
        font-weight: 400;

        .ant-tabs-tab-btn {
            color: #fff;
        }
    }
}

@primary-color: #2932E1;
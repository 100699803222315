/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '~video.js/dist/video-js.css';

// 使用 inline-block 特性垂直居中
.paddle-video-modal-wrap {
    text-align: center;

    &:after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0;
    }

    .ant-modal-close-icon {
        background-color: rgba(255, 255, 255, .2);
    }
}

.video-js {
    height: 100%;
    width: 100%;
}

.video-js + .video-js {
    display: none;
}

.paddle-video-modal {
    display: inline-block;
    max-width: 960px;
    padding: 0;
    top: 0;
    vertical-align: middle;

    &-player-wrap {
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
    }

    &-player-inner {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &-player {
        height: 100%;
        width: 100%;

        * {
            outline: none;
        }
    }

    .ant-modal-content {
        background-color: transparent;
    }

    .ant-modal-body {
        padding: 0;
    }
}



/**
 * @file PaddleHub 首页教学卡片
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-text-card2-wrap {
	margin: 20px auto 0 auto;
	width: 1198px;
}

.paddle-text-card2 {
	border: 1px solid #e3e7fd;
	border-radius: 16px;
	display: block;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

	&:hover {
		box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, .05);
	}

	&:nth-of-type(2n) {
		margin-right: 0;
	}

	&-head {
		background: #f6f7fe;
		border-radius: 16px 16px 0 0;
		padding: 20px 30px;
	}

	&-title {
		font-size: 20px;
		font-weight: 500;
		color: #000;
		letter-spacing: 0;
		line-height: 28px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&-info {
		color: #666;
		margin-top: 14px;

		&-item {
			display: inline-block;
			margin-right: 24px;
		}

		&-icon {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
			height: 16px;
			margin-right: 6px;
			position: relative;
			top: 3px;
			width: 16px;

			&-prev {
				background-image: url(./image/prev-icon.png);
			}

			&-fork {
				background-image: url(./image/fork-icon.png);
			}

			&-star {
				background-image: url(./image/star-icon.png);
			}
		}
	}

	&-body {
		padding: 20px 30px;
	}

	&-context {
		font-size: 14px;
		font-weight: 400;
		color: #666;
		letter-spacing: 0;
		line-height: 28px;
	}

	&-tags {
		font-weight: 500;
		margin-top: 10px;
		margin-right: -10px;

		> a {
			margin-right: 10px;
		}
	}

	&-link {
		color: #515eee;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 24px;
		margin-top: 30px;
		text-align: center;
	}

	&-right-icon {
		background-image: url(./image/right.png);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: inline-block;
		margin-left: 2px;
		margin-top: 4px;
		height: 16px;
		vertical-align: top;
		width: 16px;
	}
}

/**
 * @file paddle hub 首页模型列表左侧菜单
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-menu-wrap {

	.ant-collapse-ghost
		> .ant-collapse-item.paddle-hub-menu-panel-switch-wrap
		> .ant-collapse-content
		> .ant-collapse-content-box {
		padding: 0;
	}
	.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
		padding-left: 16px;
		padding-right: 12px;
	}
}

.paddle-hub-menu-item {
	margin-bottom: 0;

	.ant-form-item-control-input {
		min-height: unset;
	}

	&-wrap {
		cursor: pointer;
		outline: none;
		padding: 0 20px 0 0;
	}

	&-title {
		color: #000;
		display: flex;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 22px;
		padding: 13px 0;

		& > div {
			flex: auto;
		}

		& > div:last-of-type {
			flex: none;
		}

		&-icon {
			color: #666;

			.anticon-down {
				transform: scale(1, .8);
			}
		}
	}
}

.paddle-hub-menu-select {
	display: block;

	&.ant-select-multiple {
		.ant-select-selection-item {
			border: 1px solid #e0e0e0;
			height: 28px;
			line-height: 24px;
		}

		.ant-select-selector {
			margin: 0 -5px;
			padding: 0;
		}

		.ant-select-selector::after {
			content: none;
		}
	}

	&.ant-select-multiple:not(.ant-select-show-search) {
		.ant-select-selection-search {
			display: none;
		}
	}

	&-popup {
		.ant-cascader-menu {
			min-width: 200px;
		}

		.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
			font-weight: 400;
			background-color: transparent;
		}

		.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
			background: #f2f5ff;
		}
	}
}

.paddle-hub-menu {
	&-wrap {
		border-right: 1px solid #ebebeb;
	}

	&-title {
		color: #000;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		margin-bottom: 24px;
		padding: 0 20px 0 0;
	}

	&-clear-all {
		cursor: pointer;
		color: #666;
		float: right;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 28px;
	}

	&-panel-switch-wrap {
		.ant-collapse-content-box {
			padding-bottom: 0;
			padding-top: 0;
		}

		.ant-collapse-extra {
			margin-top: -7px;
		}
	}
}

/**
 * @file paddlehub 通用图片 demo样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import './components/picturedemo/style.less';
@import '../../../../components/video/style.less';

.paddlegan-tabs {
	margin-top: 15px;

	& > .ant-tabs-nav {
		margin-bottom: 20px;

		&::before {
			content: none;
		}

		.ant-tabs-nav-wrap {
			justify-content: center;
		}

		.ant-tabs-tab {
			font-size: 20px;
		}

		.ant-tabs-nav-list {
			margin-right: -32px;
			flex: none;
		}
	}

	&-subtitle {
		color: #333;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		margin: 0 auto;
		text-align: center;
		width: 800px;
	}

	&-video-wrap {
		height: 560px;
		margin-top: 20px;
		position: relative;
	}

	&-video {
		height: 560px;
	}

	&-video-bg-cover {
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&-video:hover &-video-bg-cover {
		display: none;
	}
}

@primary-color: #2932E1;
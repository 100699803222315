/**
 * @file ppde页面的header
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-ppde-tabs {
    background: #1527c2;
    color: #f0f0f0;
    margin-left: 170px;
    margin-top: -60px;
    position: static;
    position: sticky;
    top: 0;
    z-index: 1000;

    .ant-tabs-nav {
        border-bottom: 0;
        margin-bottom: 0;
    }

    .ant-tabs-nav-wrap {
        padding: 18px 0 9px 0;
    }

    .ant-tabs-ink-bar {
        background: rgba(255, 255, 255, .3);
        height: 3px;
    }

    .ant-tabs-tab {
        //padding-left: 0;
        //padding-right: 0;
        padding: 3px 0 8px 0;
    }

    .ant-tabs-tab:hover {
        color: #e0e0e0;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
        color: #f0f0f0;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff;
    }

    .ant-tabs-nav .ant-tabs-tab {
        margin-right: 80px;
    }

    .ant-tabs-nav .ant-tabs-tab:last-child {
        margin-right: 0;
    }

    .ant-tabs-content-holder {
        display: none;
    }

    &.ant-tabs-top > .ant-tabs-nav:before {
        content: none;
    }
}

/**
 * @file 文字卡片的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
@import '../../../../components/vertical-ellipsis/style.css';

.paddle-text-card-group {
    margin: 10px -12px -12px -12px;
}

.paddle-text-card-grid {
    padding: 12px;
}

.paddle-text-card-wrap {
    position: relative;
    z-index: 4;
}

.paddle-text-card {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 16px;
    padding: 30px 30px 25px 30px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
        box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, .05);
    }

    &-title {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-info {
        color: #333;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 21px;
        margin-top: 15px;

        span:nth-of-type(odd) {
            color: #999;
            margin-right: 10px;
        }

        span:nth-of-type(even) {
            margin-right: 40px;
        }
    }

    &-info-inner {
        height: 21px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &-text {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 10px;
    }

    &-text-inner {
        height: 78px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &-tag-group {
        color: #2931e2;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        margin-top: 10px;
    }

    .vertical-ellipsis-ellipsis {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &-expend .vertical-ellipsis-ellipsis {
        opacity: 0;
    }

}

// 一行3列的版本
.paddle-text-card-cols-3 {
    .paddle-text-card {
        &-title {
            font-size: 28px;
        }

        &-text {
            line-height: 24px;
        }
    }
}

/**
 * @file paddle hub 列表页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import '../../components/header/style';
@import '../../components/footer/style';
@import '../../components/paddle-hub-menu/style';
@import '../../components/affix-range/style';
@import '../../components/sorter/style';
@import './components/banner/style';
@import './components/paddle-hub-model-list/style';
@import './components/paddle-hub-menu/style';
@import './components/charts/style';

.paddle-hub-page {
	padding-top: @header-height-margin;

	&-banner-btn-group {
		position: absolute;
		right: 0;
		top: 20px;
	}

	&-banner-btn {
		font-size: 18px;
		font-weight: 600;
	}

	&-container {
		display: flex;
		margin: 0 auto;
		max-width: @main-max-width;
		min-height: 600px;
		padding-bottom: 120px;
	}

	&-container-left {
		bottom: 120px;
		flex: none;
		left: 0;
		width: 240px;
	}

	&-container-center {
		flex: auto;
	}

	&-container-center-top {
		padding: 0 20px;
	}

	&-container-right {
		flex: none;
		padding-top: 52px;
		width: 240px;
	}

	&-search-auto-complete {
		width: 320px;
	}

	&-search-input {
		border-radius: 999999px;
		width: 320px;

		.ant-input-clear-icon {
			margin-right: -12px;
		}

		.anticon-search {
			visibility: hidden;
		}

		.ant-input-clear-icon-hidden + .anticon-search {
			visibility: visible;
		}
	}
}

.paddle-hub-model-list-wrap {
	padding: 20px;
}

.paddle-hub-sorter {
	margin-left: 30px;
}

@primary-color: #2932E1;
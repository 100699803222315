/**
 * @file paddlehub demo ai写作 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file paddlehub demo ai写作面板 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file paddlehub demo ai写作面板 tag
 * @author FengGuang(fengguang01@baidu.com)
 */
.ai-writing-example-tag {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin: 5px 3px;
  padding: 0 12px;
}
.ai-writing-example-tag:active,
.ai-writing-example-tag.ant-tag-checkable-checked {
  background: rgba(41, 50, 225, 0.05);
  border: 1px solid #2932e1;
  border-radius: 4px;
  color: #2932e1;
}
.ai-writing-show-pane-exampletag-label {
  float: left;
  line-height: 32px;
  margin: 6px 0;
}
.ai-writing-show-pane-exampletag-right {
  margin-left: 60px;
}
.ai-writing-show-pane-form {
  margin-top: 30px;
}
.ai-writing-show-pane-input {
  margin-right: 20px;
  width: 420px;
}
.ai-writing-show-pane-switch-btn {
  color: #2932E1;
  cursor: pointer;
  float: right;
}
.ai-writing-show-pane-switch-btn .anticon {
  margin-right: 10px;
}
.ai-writing-show-pane-errorinfo {
  color: #ea2e2e;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 4px;
}
.ai-writing-show-pane-result-wrap {
  display: table;
  margin-top: 4px;
  min-height: 276px;
  width: 100%;
}
.ai-writing-show-pane-result {
  background-image: url(./components/show-pane/image/result-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  display: table-cell;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 4px;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
}
.ai-writing-show-pane-result-inner {
  padding: 20px;
}
.ai-writing {
  border: 1px solid #d8d8d8;
  margin-top: 40px;
}
.ai-writing .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
}
.ai-writing .ant-tabs-content {
  padding: 30px;
}
.ai-writing .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ai-writing .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 0;
}
.ai-writing .ant-tabs-nav {
  min-width: 180px;
}
.ai-writing .ant-tabs-ink-bar {
  display: none !important;
}
.ai-writing .ant-tabs-nav {
  margin-bottom: 20px;
  margin-top: 20px;
}
.ai-writing .ant-tabs-tab {
  font-size: 20px;
}
.ai-writing .ant-tabs-nav .ant-tabs-tab {
  margin-bottom: 0;
  padding-bottom: 15px;
  padding-top: 15px;
}
.ai-writing .ant-tabs-nav .ant-tabs-tab-active {
  background: #2932E1;
  font-weight: 400;
}
.ai-writing .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

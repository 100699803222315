.p-x-ai-cloud-fast-start{
    width: 1200px;
    margin: 0 auto;
    // margin-bottom: 80px;
    &::after {
        content: "";
        display: block;
        overflow:hidden;
        visibility:hidden;
        height: 0;
        clear: both;
    };
    &-title {
        height: 42px;
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        text-align: center;
        margin-bottom: 60px;
    };
    .p-x-ai-cloud-fast-start-modules-wrapper {
        display: flex;
        justify-content: center;
        &::after {
            content: "";
            display: block;
            overflow:hidden;
            visibility:hidden;
            height: 0;
            clear: both;
        };
    };
    .p-x-ai-cloud-fast-start-modules {
        display: flex;
        margin-bottom: 60px;
        &::after {
            content: "";
            display: block;
            overflow:hidden;
            visibility:hidden;
            height: 0;
            clear: both;
        };
        .p-x-ai-cloud-fast-start-modules-box {
            display: flex;
            &:nth-of-type(4) {
                .p-x-ai-cloud-fast-start-module {
                    margin-right: 0px;
                };
             
            };
            .p-x-ai-cloud-fast-start-module {
                    width: 64px;
                    height: 90px;
                    margin-right: 58px;
                   
                   .p-x-ai-cloud-fast-start-module-img {
                        height: 54px;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 12px;
                        .p-x-ai-cloud-fast-start-module-img-box {
                            width: 54px;
                            height: 54px;
                            object-fit: contain;
                            display: block;
                        };
                    };
                    &-title {
                        height: 24px;
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        color: #000000;
                        letter-spacing: 0;
                        text-align: center;
                        line-height: 24px;
                        font-weight: 400;
                    };

                };
              .p-x-ai-cloud-fast-start-module-line {
                width: 156px;
                height: 1px;
                background: rgba(204,204,204,1);
                margin: 28px 58px 0px 0px;
              };  
        };

    };

    .p-x-ai-cloud-fast-start-btn {
        display: flex;
        justify-content: center;
        .ant-btn-primary:focus {
            color: #FFFFFF;
            background:  #1823BA !important;
            border-color:  #1823BA !important;
        };
        &-skip {
            margin-right: 16px;
            background: #2932E1;
            border-radius: 20px;
            span {
                display: inline-block;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                line-height: 20px;
                font-weight: 500;
            };
           
            &:hover {
                background: #5156ED;
                border: 1px solid rgba(81,86,237,1);
                border-radius: 20px;
            };
            &:disabled {
                background: #C5D0FF;
                border: none;
            };
            width: 120px;
            height: 40px;
            border-radius: 20px;
        };
    };
}
@primary-color: #2932E1;
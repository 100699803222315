/**
 * @file paddlehub demo 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../components/video-modal/~video.js/dist/video-js.css';
@import '../../components/vertical-ellipsis/style.css';
@import '../../components/vertical-ellipsis/style.css';
html,
body {
  min-width: 1400px;
  margin: 0;
  padding: 0;
}
.page {
  font-size: 14px;
  font-weight: 400;
}
.paddle-context-wrap {
  position: relative;
}
.paddle-context-link-more {
  bottom: 0;
  position: absolute;
  right: 0;
}
.paddle-context-link-more a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.paddle-context-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  margin-top: 54px;
}
.paddle-context-h3 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 15px;
}
.paddle-context-h3 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-context-h4 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}
.paddle-context-h4 a {
  color: #2932E1;
  margin-left: 8px;
}
.clear-both:after {
  content: '';
  display: block;
  line-height: 0;
  clear: both;
}
.link-button {
  border: none;
  display: inline;
  margin: 0;
  font-weight: 500;
  padding: 0;
  color: #2932E1;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.link-button:active,
.link-button:hover {
  text-decoration: none;
  outline: 0;
}
.link-button:hover {
  color: #515eed;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-head-search-ac {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 300px;
}
.paddle-head-search-ac .ant-input-prefix {
  color: #2932E1;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 0px;
}
.paddle-head-search-ac.ant-select-open {
  width: 800px;
}
.paddle-head-search-ac .ant-input {
  height: 22px;
  line-height: 22px;
}
.paddle-head-search-ac .ant-input-affix-wrapper {
  margin-bottom: 5px;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless .ant-input-prefix {
  color: #fff;
}
.paddle-head-search-ac .ant-input-affix-wrapper-focused {
  border-radius: 0 !important;
}
.paddle-head-search-ac .ant-input-borderless {
  color: #fff;
}
.paddle-head-search-ac .ant-input-borderless::-webkit-input-placeholder,
.paddle-head-search-ac .ant-input-borderless::placeholder {
  color: rgba(254, 253, 253, 0.5);
}
.paddle-head-search-ac-overlay {
  padding: 0 0 4px 0;
}
.paddle-head-search-ac-overlay-ellipsis {
  display: inline-block;
  flex: none;
  overflow: hidden;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.paddle-head-search-ac-overlay .ant-select-item {
  padding: 5px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item,
.paddle-head-search-ac-overlay .ant-select-item a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay .ant-select-item-group {
  padding: 23px 40px 8px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item-group,
.paddle-head-search-ac-overlay .ant-select-item-group a {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay-more {
  color: #2932E1;
  padding: 12px 40px;
}
.paddle-head-search-ac-overlay-divider {
  border-top: 1px solid #dadcdf;
  margin: 24px 40px 0 40px;
}
.paddle-head-search-ac-overlay-item {
  align-items: center;
  display: flex;
}
.paddle-head-search-ac-overlay-item-title {
  color: #666;
  display: inline-block;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-head-search-ac-overlay-item-title em {
  color: #000;
  font-weight: 700;
}
.paddle-head-search-ac-overlay-item-divider {
  border-left: 1px solid #dadcdf;
  display: inline-block;
  height: 14px;
}
.paddle-head-search-ac-overlay-item-type {
  padding-left: 18px;
}
.paddle-head-search-ac-overlay-loading-wrap {
  margin: 24px 40px;
}
.paddle-header-menu-popup .ant-select-dropdown {
  border-radius: 0;
}
.paddle-head-search-ac-overlay-type2 .ant-select-item-group,
.paddle-head-search-ac-overlay-type2 .ant-select-item-group a {
  color: #000;
}
.paddle-header-wrap {
  font-size: 14px;
  font-weight: 400;
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  z-index: 999;
}
.paddle-header {
  font-weight: 400;
  height: 60px;
  margin: 0 30px;
  min-width: 1200px;
  overflow: hidden;
}
.paddle-header-background {
  background-color: #1527c2;
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
  color: #fff;
}
.paddle-header .ant-menu-item-selected a {
  color: #fff;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 60px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  width: 100%;
}
.paddle-header-logo {
  margin-top: 13px;
  width: 96px;
}
.paddle-header-menu {
  display: inline-block;
  padding: 16px 0 16px 33px;
  vertical-align: top;
  min-width: 697px;
}
.paddle-header-right {
  float: right;
  height: 60px;
}
.paddle-header-right-feedback {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}
.paddle-header-right-feedback-close {
  position: relative;
  top: 7px;
  left: calc(100% - 22px);
}
.paddle-header-right-feedback-close .feedback-close-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  background-image: url('../../images/headerfeedback/icon-close.png');
  background-size: cover;
}
.paddle-header-right-feedback-button {
  cursor: pointer;
  position: absolute;
  width: 92px;
  height: 32px;
  left: 10%;
  top: 50%;
  background-image: url('../../images/headerfeedback/button.png');
  background-size: cover;
}
.paddle-header-right-feedback-button span {
  margin-left: 10px;
  text-align: center;
  font-size: 12.6px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 40px;
}
.paddle-header-right-feedback-popover .ant-popover-content {
  position: relative;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin-top: 4px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-arrow {
  top: -4px;
  border: #2932e1;
  background-color: #2932e1;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner {
  width: 314px;
  height: 116px;
  background-image: url('../../images/headerfeedback/bg.png');
  background-size: cover;
  border-radius: 0px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  border-bottom: 0px;
}
.paddle-header-right-feedback-icon {
  width: 40px;
  height: 40px;
}
.paddle-header-right-language {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
}
.paddle-header-right-language span {
  margin: 0 5px;
  cursor: pointer;
}
.paddle-header-right-language span:hover {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-zh {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-en {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
}
.paddle-header-search-wrap {
  display: inline-block;
  line-height: 60px;
  margin-right: 10px;
  width: 280px;
  vertical-align: top;
}
.paddle-header-links-menu {
  display: inline-block;
  height: 60px;
  margin: 0 -17px 0 17px;
  padding: 16px 0 16px 0;
  vertical-align: top;
}
.paddle-header .ant-avatar .anticon {
  margin-right: 0;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 14px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu:nth-last-child(1) {
  margin: 0 16px;
  padding: 0;
}
.paddle-header .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline),
.paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-item:hover > a,
.paddle-header .ant-menu-item-active > a,
.paddle-header .ant-menu:not(.ant-menu-inline) > a,
.paddle-header .ant-menu-submenu-open > a,
.paddle-header .ant-menu-submenu-active > a,
.paddle-header .ant-menu-submenu-title > a,
.paddle-header .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.paddle-header .ant-menu-item,
.paddle-header .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-item.paddle-header-user-avatar-menu-item,
.paddle-header .ant-menu-submenu.paddle-header-user-avatar-menu-item {
  border-bottom: 3px solid transparent;
  height: 27px;
  margin-top: -2px;
  vertical-align: top;
}
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
}
.paddle-header .ant-menu-item a {
  color: #fff;
}
.paddle-header .ant-menu-item a:hover {
  color: #fff;
}
.paddle-header .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.paddle-header-item-group-list .ant-menu-item,
.paddle-header-item-group-list .ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  height: 27px;
}
.paddle-header-menu-popup {
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 60px;
  z-index: 1499;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item {
  font-weight: 500;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-active {
  background: #f7f7f7;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item,
.paddle-header-menu-popup .ant-menu .ant-menu-submenu {
  margin: 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-item,
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-submenu {
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header-submenu-popup-large {
  max-width: 100%;
  right: 0;
}
.paddle-header-submenu-popup-large .ant-menu {
  display: flex;
  padding: 22px 0 42px 55px;
  text-align: left;
}
.paddle-header-submenu-popup-large .ant-menu > * {
  flex-grow: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group {
  float: left;
  padding: 0 15px;
  width: 225px;
  z-index: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-title {
  border-bottom: 1px solid #e9eaec;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  margin: 0 10px;
  padding: 0;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-list {
  margin-top: 12px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item:hover {
  background: #f7f7f7;
  color: #1434c2;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title {
  width: 315px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-title {
  display: none;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item {
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover),
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover) > a {
  color: #000;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-list {
  margin-top: 0;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-right-icon {
  margin-left: 10px;
  transform: scale(0.65, 0.8);
}
.paddle-header-submenu-popup-large .ant-menu-hidden {
  display: none;
}
.paddle-header-submenu-popup-large-tag {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
.paddle-header-submenu-popup-large-tag.red {
  color: #ff3912;
}
.paddle-header-submenu-popup-large-tag.blue {
  color: #1a73e8;
}
.paddle-header-submenu-popup-large-tag.green {
  color: #00b359;
}
.paddle-header-submenu-popup-large-bg {
  background: #fff;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 21%;
  z-index: 0;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 60px;
  height: 60px;
  margin-left: -2px;
}
.paddle-header-links-link:after {
  display: block;
  content: attr(title);
  font-weight: 500;
  visibility: hidden;
  height: 0;
  margin-bottom: -1px;
  overflow: hidden;
}
@media screen and (max-width: 1260px) {
  .paddle-header {
    margin: 0 auto;
    width: 1400px;
  }
}
/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-banner {
  padding-top: 50px;
  position: relative;
}
.paddle-banner-container {
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 40px;
  position: relative;
}
.paddle-banner-divider {
  border-bottom: 1px solid #d1d1d1;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-title {
  color: #000;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 57px;
}
.paddle-banner-subtitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 15px;
  text-align: justify;
}
.paddle-banner-btn-group {
  bottom: 15%;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-btn {
  border-radius: 999999px;
  font-size: 24px;
  color: #1434c2;
  height: 50px;
  letter-spacing: 1px;
  width: 180px;
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #000;
  display: block;
  min-height: 403px;
}
.paddle-footer,
.paddle-footer a {
  color: #999;
}
.paddle-footer-container {
  max-width: 1400px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0;
}
.paddle-footer-group {
  width: 191px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  font-size: 18px;
}
.paddle-footer-item-list {
  float: left;
  margin-top: 25px;
}
.paddle-footer-item,
.paddle-footer-item a {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}
.paddle-footer-qr-wrap {
  float: right;
  margin-bottom: -11px;
  margin-right: -55px;
}
.paddle-footer-qr-item {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 180px;
  text-align: center;
}
.paddle-footer-qr-context {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.paddle-footer-qr-context-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-title {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  text-align: center;
  vertical-align: top;
}
.paddle-footer-qr-subtitle {
  font-size: 12px;
}
.paddle-footer-bottom {
  background: #000;
  border-top: 1px solid #242424;
  color: #999;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.paddle-footer .beian {
  margin-left: 100px;
}
.paddle-footer .footer-mark-number {
  margin-left: 80px;
}
.paddle-footer .record-link-style:hover {
  color: rgba(153, 153, 153, 0.8);
}
/**
 * @file 垂直tab+列表
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #24292e;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}
.markdown-body .pl-c {
  color: #6a737d;
}
.markdown-body .pl-c1,
.markdown-body .pl-s .pl-v {
  color: #005cc5;
}
.markdown-body .pl-e,
.markdown-body .pl-en {
  color: #6f42c1;
}
.markdown-body .pl-smi,
.markdown-body .pl-s .pl-s1 {
  color: #24292e;
}
.markdown-body .pl-ent {
  color: #22863a;
}
.markdown-body .pl-k {
  color: #d73a49;
}
.markdown-body .pl-s,
.markdown-body .pl-pds,
.markdown-body .pl-s .pl-pse .pl-s1,
.markdown-body .pl-sr,
.markdown-body .pl-sr .pl-cce,
.markdown-body .pl-sr .pl-sre,
.markdown-body .pl-sr .pl-sra {
  color: #032f62;
}
.markdown-body .pl-v,
.markdown-body .pl-smw {
  color: #e36209;
}
.markdown-body .pl-bu {
  color: #b31d28;
}
.markdown-body .pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}
.markdown-body .pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}
.markdown-body .pl-c2::before {
  content: "^M";
}
.markdown-body .pl-sr .pl-cce {
  font-weight: bold;
  color: #22863a;
}
.markdown-body .pl-ml {
  color: #735c0f;
}
.markdown-body .pl-mh,
.markdown-body .pl-mh .pl-en,
.markdown-body .pl-ms {
  font-weight: bold;
  color: #005cc5;
}
.markdown-body .pl-mi {
  font-style: italic;
  color: #24292e;
}
.markdown-body .pl-mb {
  font-weight: bold;
  color: #24292e;
}
.markdown-body .pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}
.markdown-body .pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}
.markdown-body .pl-mc {
  color: #e36209;
  background-color: #ffebda;
}
.markdown-body .pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}
.markdown-body .pl-mdr {
  font-weight: bold;
  color: #6f42c1;
}
.markdown-body .pl-ba {
  color: #586069;
}
.markdown-body .pl-sg {
  color: #959da5;
}
.markdown-body .pl-corl {
  text-decoration: underline;
  color: #032f62;
}
.markdown-body .octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}
.markdown-body a {
  background-color: transparent;
}
.markdown-body a:active,
.markdown-body a:hover {
  outline-width: 0;
}
.markdown-body strong {
  font-weight: inherit;
}
.markdown-body strong {
  font-weight: bolder;
}
.markdown-body h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.markdown-body img {
  border-style: none;
}
.markdown-body code,
.markdown-body kbd,
.markdown-body pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
.markdown-body hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.markdown-body input {
  font: inherit;
  margin: 0;
}
.markdown-body input {
  overflow: visible;
}
.markdown-body [type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.markdown-body * {
  box-sizing: border-box;
}
.markdown-body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.markdown-body a {
  color: #0366d6;
  text-decoration: none;
}
.markdown-body a:hover {
  text-decoration: underline;
}
.markdown-body strong {
  font-weight: 600;
}
.markdown-body hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}
.markdown-body hr::before {
  display: table;
  content: "";
}
.markdown-body hr::after {
  display: table;
  clear: both;
  content: "";
}
.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
}
.markdown-body td,
.markdown-body th {
  padding: 0;
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body h1 {
  font-size: 32px;
  font-weight: 600;
}
.markdown-body h2 {
  font-size: 24px;
  font-weight: 600;
}
.markdown-body h3 {
  font-size: 20px;
  font-weight: 600;
}
.markdown-body h4 {
  font-size: 16px;
  font-weight: 600;
}
.markdown-body h5 {
  font-size: 14px;
  font-weight: 600;
}
.markdown-body h6 {
  font-size: 12px;
  font-weight: 600;
}
.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}
.markdown-body blockquote {
  margin: 0;
}
.markdown-body ul,
.markdown-body ol {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}
.markdown-body ul ul ol,
.markdown-body ul ol ol,
.markdown-body ol ul ol,
.markdown-body ol ol ol {
  list-style-type: lower-alpha;
}
.markdown-body dd {
  margin-left: 0;
}
.markdown-body code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.markdown-body .octicon {
  vertical-align: text-bottom;
}
.markdown-body .pl-0 {
  padding-left: 0 !important;
}
.markdown-body .pl-1 {
  padding-left: 4px !important;
}
.markdown-body .pl-2 {
  padding-left: 8px !important;
}
.markdown-body .pl-3 {
  padding-left: 16px !important;
}
.markdown-body .pl-4 {
  padding-left: 24px !important;
}
.markdown-body .pl-5 {
  padding-left: 32px !important;
}
.markdown-body .pl-6 {
  padding-left: 40px !important;
}
.markdown-body::before {
  display: table;
  content: "";
}
.markdown-body::after {
  display: table;
  clear: both;
  content: "";
}
.markdown-body > *:first-child {
  margin-top: 0 !important;
}
.markdown-body > *:last-child {
  margin-bottom: 0 !important;
}
.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.markdown-body .anchor:focus {
  outline: none;
}
.markdown-body p,
.markdown-body blockquote,
.markdown-body ul,
.markdown-body ol,
.markdown-body dl,
.markdown-body table,
.markdown-body pre {
  margin-top: 0;
  margin-bottom: 16px;
}
.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}
.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}
.markdown-body blockquote > :first-child {
  margin-top: 0;
}
.markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #c6cbd1;
  border-bottom-color: #959da5;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #959da5;
}
.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden;
}
.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}
.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}
.markdown-body h1 {
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid #eaecef;
}
.markdown-body h2 {
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid #eaecef;
}
.markdown-body h3 {
  font-size: 1.25em;
}
.markdown-body h4 {
  font-size: 1em;
}
.markdown-body h5 {
  font-size: 0.875em;
}
.markdown-body h6 {
  font-size: 0.85em;
  color: #6a737d;
}
.markdown-body ul,
.markdown-body ol {
  padding-left: 2em;
}
.markdown-body ul ul,
.markdown-body ul ol,
.markdown-body ol ol,
.markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-body li {
  word-wrap: break-all;
}
.markdown-body li > p {
  margin-top: 16px;
}
.markdown-body li + li {
  margin-top: 0.25em;
}
.markdown-body dl {
  padding: 0;
}
.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
}
.markdown-body table th {
  font-weight: 600;
}
.markdown-body table th,
.markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
.markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}
.markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
.markdown-body img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
}
.markdown-body img[align=right] {
  padding-left: 20px;
}
.markdown-body img[align=left] {
  padding-right: 20px;
}
.markdown-body code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}
.markdown-body pre {
  word-wrap: normal;
}
.markdown-body pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.markdown-body .highlight {
  margin-bottom: 16px;
}
.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}
.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}
.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.markdown-body .full-commit .btn-outline:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5;
}
.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #d1d5da;
  border-bottom-color: #c6cbd1;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #c6cbd1;
}
.markdown-body :checked + .radio-label {
  position: relative;
  z-index: 1;
  border-color: #0366d6;
}
.markdown-body .task-list-item {
  list-style-type: none;
}
.markdown-body .task-list-item + .task-list-item {
  margin-top: 3px;
}
.markdown-body .task-list-item input {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.markdown-body hr {
  border-bottom-color: #eee;
}
.paddle-vertical-tabs-list {
  margin: 30px auto 0 auto;
  min-height: 240px;
  position: relative;
  width: 1400px;
}
.paddle-vertical-tabs-list-menu-background {
  background: #f5f6f9;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 180px;
  z-index: -1;
}
.paddle-vertical-tabs-list-menu {
  display: inline-block;
  float: left;
  width: 180px;
}
.paddle-vertical-tabs-list-menu-item {
  color: #666;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding: 16px 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-vertical-tabs-list-menu-item:hover {
  color: #515eee;
}
.paddle-vertical-tabs-list-menu-item-selected {
  background-color: #2932e1;
  color: #fff;
}
.paddle-vertical-tabs-list-menu-item-selected:hover {
  color: #fff;
}
.paddle-vertical-tabs-list-body {
  margin-left: 240px;
}
.paddle-vertical-tabs-list-item {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.paddle-vertical-tabs-list-item:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.paddle-vertical-tabs-list-item-body {
  margin-right: 340px;
}
.paddle-vertical-tabs-list-item-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
}
.paddle-vertical-tabs-list-item-context {
  margin-top: 12px;
}
.paddle-vertical-tabs-list-item-context-form {
  font-size: 14px;
  line-height: 1.5;
  margin: -4px -6px -4px -6px;
  max-width: 100%;
  width: 100%;
}
.paddle-vertical-tabs-list-item-context-form td {
  padding: 4px 6px;
}
.paddle-vertical-tabs-list-item-context-form td:not(.paddle-vertical-tabs-list-item-context-form-label) {
  width: 100%;
}
.paddle-vertical-tabs-list-item-context-form .copy-btn {
  display: none;
}
.paddle-vertical-tabs-list-item-context-form-model-list {
  margin: 0 -10px;
  overflow: visible;
  text-overflow: clip;
  white-space: normal;
}
.paddle-vertical-tabs-list-item-context-form-model-list a {
  display: inline-block;
  margin: 0 10px;
}
.paddle-vertical-tabs-list-item-context-form-label {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
  padding-right: 12px;
  white-space: nowrap;
  vertical-align: top;
  width: auto;
}
.paddle-vertical-tabs-list-item-tags {
  margin-top: 16px;
}
.paddle-vertical-tabs-list-item-tag {
  border: 1px solid #2931E2;
  border-radius: 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #2931E2;
  letter-spacing: 0;
  line-height: 1;
  min-width: 88px;
  padding: 8px 14px;
  text-align: center;
}
.paddle-vertical-tabs-list-item-tag + .paddle-vertical-tabs-list-item-tag {
  margin-left: 10px;
}
.paddle-vertical-tabs-list-item-tag-disabled,
.paddle-vertical-tabs-list-item-tag-disabled:hover {
  border-color: #cecece;
  color: #cecece;
  pointer-events: none;
  cursor: none;
}
.paddle-vertical-tabs-list-item-img {
  background-color: #e5e5e5;
  float: right;
  height: 168px;
  width: 280px;
}
.paddle-vertical-tabs-list-item-img img {
  height: 100%;
  width: 100%;
}
/**
 * @file paddle 客户案例 视频文字卡片 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 视频封面
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-video-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.paddle-video-cover-hover-autoplay {
  cursor: pointer;
}
/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-video-modal-wrap {
  text-align: center;
}
.paddle-video-modal-wrap:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.paddle-video-modal-wrap .ant-modal-close-icon {
  background-color: rgba(255, 255, 255, 0.2);
}
.video-js {
  height: 100%;
  width: 100%;
}
.video-js + .video-js {
  display: none;
}
.paddle-video-modal {
  display: inline-block;
  max-width: 960px;
  padding: 0;
  top: 0;
  vertical-align: middle;
}
.paddle-video-modal-player-wrap {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.paddle-video-modal-player-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-video-modal-player {
  height: 100%;
  width: 100%;
}
.paddle-video-modal-player * {
  outline: none;
}
.paddle-video-modal .ant-modal-content {
  background-color: transparent;
}
.paddle-video-modal .ant-modal-body {
  padding: 0;
}
.paddle-vertical-video-text-card-body {
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  margin-top: 40px;
  overflow: hidden;
}
.paddle-vertical-video-text-card-video {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 320px;
  position: relative;
}
.paddle-vertical-video-text-card-video-background-video {
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 2;
}
.paddle-vertical-video-text-card-video-background-video:hover {
  opacity: 1;
}
.paddle-vertical-video-text-card-video-play-btn {
  background-image: url(../../components/hub-components/vertical-video-text-card/img/play.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  height: 48px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  z-index: 1;
}
.paddle-vertical-video-text-card-text {
  padding: 40px 98px 40px 40px;
}
.paddle-vertical-video-text-card-text-title {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
}
.paddle-vertical-video-text-card-text-text {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 20px;
}
.paddle-vertical-video-text-card-text-btns {
  margin-top: 44px;
}
.paddle-vertical-video-text-card-text-btn {
  border-color: transparent;
  border-radius: 999999px;
  color: #2932E1;
  padding-left: 32px;
  padding-right: 32px;
}
.paddle-vertical-video-text-card-text-btn:hover {
  opacity: 0.8;
}
/**
 * @file 文字卡片的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-text-card-group {
  margin: 10px -12px -12px -12px;
}
.paddle-text-card-grid {
  padding: 12px;
}
.paddle-text-card-wrap {
  position: relative;
  z-index: 4;
}
.paddle-text-card {
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 16px;
  padding: 30px 30px 25px 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-text-card:hover {
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
}
.paddle-text-card-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.paddle-text-card-info {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 15px;
}
.paddle-text-card-info span:nth-of-type(odd) {
  color: #999;
  margin-right: 10px;
}
.paddle-text-card-info span:nth-of-type(even) {
  margin-right: 40px;
}
.paddle-text-card-info-inner {
  height: 21px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-text-card-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 10px;
}
.paddle-text-card-text-inner {
  height: 78px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-text-card-tag-group {
  color: #2931e2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
}
.paddle-text-card .vertical-ellipsis-ellipsis {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-text-card-expend .vertical-ellipsis-ellipsis {
  opacity: 0;
}
.paddle-text-card-cols-3 .paddle-text-card-title {
  font-size: 28px;
}
.paddle-text-card-cols-3 .paddle-text-card-text {
  line-height: 24px;
}
/**
 * @file PaddleHub 首页教学卡片
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-text-card2-wrap {
  margin: 20px auto 0 auto;
  width: 1198px;
}
.paddle-text-card2 {
  border: 1px solid #e3e7fd;
  border-radius: 16px;
  display: block;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-text-card2:hover {
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
}
.paddle-text-card2:nth-of-type(2n) {
  margin-right: 0;
}
.paddle-text-card2-head {
  background: #f6f7fe;
  border-radius: 16px 16px 0 0;
  padding: 20px 30px;
}
.paddle-text-card2-title {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.paddle-text-card2-info {
  color: #666;
  margin-top: 14px;
}
.paddle-text-card2-info-item {
  display: inline-block;
  margin-right: 24px;
}
.paddle-text-card2-info-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 16px;
  margin-right: 6px;
  position: relative;
  top: 3px;
  width: 16px;
}
.paddle-text-card2-info-icon-prev {
  background-image: url(./components/text-card2/image/prev-icon.png);
}
.paddle-text-card2-info-icon-fork {
  background-image: url(./components/text-card2/image/fork-icon.png);
}
.paddle-text-card2-info-icon-star {
  background-image: url(./components/text-card2/image/star-icon.png);
}
.paddle-text-card2-body {
  padding: 20px 30px;
}
.paddle-text-card2-context {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  letter-spacing: 0;
  line-height: 28px;
}
.paddle-text-card2-tags {
  font-weight: 500;
  margin-top: 10px;
  margin-right: -10px;
}
.paddle-text-card2-tags > a {
  margin-right: 10px;
}
.paddle-text-card2-link {
  color: #515eee;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 30px;
  text-align: center;
}
.paddle-text-card2-right-icon {
  background-image: url(./components/text-card2/image/right.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  margin-left: 2px;
  margin-top: 4px;
  height: 16px;
  vertical-align: top;
  width: 16px;
}
/**
 * @file 文字卡片的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-icon-text-card-group {
  margin-top: 24px;
}
.paddle-icon-text-card {
  border: 1px solid #d8d8d8;
  border-radius: 16px;
  display: flex;
  padding: 24px 24px 16px 24px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-icon-text-card:hover {
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
}
.paddle-icon-text-card-icon-wrap {
  flex: none;
  width: 40px;
}
.paddle-icon-text-card-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  display: block;
  width: 32px;
  height: 32px;
}
.paddle-icon-text-card-info {
  flex: auto;
}
.paddle-icon-text-card-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
}
.paddle-icon-text-card-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
  position: relative;
  word-wrap: break-word;
}
.paddle-icon-text-card-tag {
  height: 21px;
}
.paddle-icon-text-card-tag-group {
  color: #2931e2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 10px;
}
.paddle-hub-demo-page {
  padding-top: 0px;
}
.paddle-hub-demo-page-banner-btn-group {
  margin-top: 30px;
}
.paddle-hub-demo-page-banner-btn {
  border-radius: 999999px;
  font-size: 14px;
  margin-right: 20px;
  width: 140px;
}
.paddle-hub-demo-page-banner-selector {
  position: absolute;
  right: 0;
  top: 18px;
  width: 140px;
}
.paddle-hub-demo-page-content {
  padding-bottom: 20px;
  max-width: 1400px;
  margin: 0 auto;
}
.paddle-hub-demo-page-context-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  min-height: 45px;
  margin-top: 54px;
  text-align: center;
}
.paddle-hub-demo-page-context-h3 {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 21px;
  min-height: 21px;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.paddle-hub-demo-page-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  margin-top: 54px;
  text-align: center;
}
.paddle-hub-demo-page-h3 {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 10px;
  opacity: 0.8;
  text-align: center;
}

/**
 * @file 垂直tab+列表
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../markdown-container/style';

.paddle-vertical-tabs-list {
    margin: 30px auto 0 auto;
    min-height: 240px;
    position: relative;
    width: @main-max-width;

    &-menu-background {
        background: #f5f6f9;
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 180px;
        z-index: -1;
    }

    &-menu {
        display: inline-block;
        float: left;
        width: 180px;
    }

    &-menu-item {
        color: #666;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 28px;
        padding: 16px 30px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

        &:hover {
            color: #515eee;
        }

        &-selected {
            background-color: #2932e1;
            color: #fff;
        }

        &-selected:hover {
            color: #fff;
        }
    }

    &-body {
        margin-left: 240px;
    }

    &-item {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 24px;
        padding-bottom: 24px;

        &:last-of-type {
            border-bottom: 0;
            margin-bottom: 0;
        }

        &-body {
            margin-right: 340px;
        }

        &-title {
            font-size: 20px;
            font-weight: 500;
            color: #000;
            letter-spacing: 0;
            line-height: 28px;
        }

        &-context {
            margin-top: 12px;
        }

        &-context-form {
            font-size: 14px;
            line-height: 1.5;
            margin: -4px -6px -4px -6px;
            max-width: 100%;
            width: 100%;

            td {
                padding: 4px 6px;
            }

            td:not(.paddle-vertical-tabs-list-item-context-form-label) {
                width: 100%;
            }

            .copy-btn {
                display: none;
            }
        }

        &-context-form-model-list {
            margin: 0 -10px;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;

            a {
                display: inline-block;
                margin: 0 10px;
            }
        }

        &-context-form-label {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            letter-spacing: 0;
            line-height: 28px;
            padding-right: 12px;
            white-space: nowrap;
            vertical-align: top;
            width: auto;
        }

        &-tags {
            margin-top: 16px;
        }

        &-tag {
            border: 1px solid #2931E2;
            border-radius: 20px;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            color: #2931E2;
            letter-spacing: 0;
            line-height: 1;
            min-width: 88px;
            padding: 8px 14px;
            text-align: center;
        }

        &-tag + &-tag {
            margin-left: 10px;
        }

        &-tag-disabled,
        &-tag-disabled:hover {
            border-color: #cecece;
            color: #cecece;
            pointer-events: none;
            cursor: none;
        }

        &-img {
            background-color: #e5e5e5;
            float: right;
            height: 168px;
            width: 280px;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.voice_header {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 50px;
}
.voice_header .voice_header_title {
  width: 100%;
  height: 57px;
  font-family: PingFangSC-Medium;
  font-size: 38px;
  color: #000000;
  letter-spacing: 0;
  line-height: 57px;
  font-weight: 500;
  margin-bottom: 15px;
}
.voice_header .voice_header_title_content {
  width: 1163px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #575757;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 24px;
}
.voice_header .voice_header_details {
  width: 100%;
}
.voice_header .voice_header_details .voice_header_details_list {
  display: inline-block;
  text-align: center;
  height: 40px;
  background: #2932E1;
  border-radius: 20px;
  padding: 0 16px;
  box-sizing: border-box;
  line-height: 40px;
  margin-right: 24px;
}
.voice_header .voice_header_details .voice_header_details_list:nth-last-of-type(1) {
  margin-right: 0;
}
.voice_header .voice_header_details .voice_header_details_list:hover {
  height: 40px;
  background: #515EEE;
  border-radius: 20px;
}
.voice_header .voice_header_details .voice_header_details_list_a {
  display: inline-block;
  height: 20px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
}
.voice_header .line {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  background: #D1D1D1;
  margin-bottom: 24px;
}
.voice_header .voice_header_introduce {
  width: 100%;
  overflow: hidden;
  margin-bottom: 80px;
  display: flex;
}
.voice_header .voice_header_introduce .voice_header_introduce_List {
  width: 380px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 16px;
  margin-right: 30px;
  padding-top: 20px;
  box-sizing: border-box;
}
.voice_header .voice_header_introduce .voice_header_introduce_List:hover {
  width: 380px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.voice_header .voice_header_introduce .voice_header_introduce_List:nth-last-of-type(1) {
  margin-right: 0;
}
.voice_header .voice_header_introduce .voice_header_introduce_List_top {
  width: 100%;
  padding: 20px,0,60px,20px;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 12px;
  vertical-align: middle;
}
.voice_header .voice_header_introduce .voice_header_introduce_List_top img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  display: block;
  margin-left: 20px;
  margin-right: 8px;
}
.voice_header .voice_header_introduce .voice_header_introduce_List_top span {
  display: block;
  width: 100%;
  height: 32px;
  font-family: PingFangSC-Medium;
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 500;
}
.voice_header .voice_header_introduce .voice_header_introduce_List_bottom {
  padding-left: 60px;
  box-sizing: border-box;
  margin-bottom: 22px;
}
.voice_header .voice_header_introduce .voice_header_introduce_List_bottom .voice_header_introduce_List_bottom_content {
  width: 297px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: 400;
}

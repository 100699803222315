.qrcode {
    width: 126px;
    height: 130px;
    background-color: #FFFFFF;
    padding: 8px ;
    box-sizing: border-box;
      // 二维码图片
      .qrcode-img {
        display: block;
        width: 110px;
        height: 110px;
        background-size: contain;
    };
};
@primary-color: #2932E1;
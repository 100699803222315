/**
 * @file paddle 学术生态详情 公共样式
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
 
 @import '../../../style/global';
 @import '../../../components/header/style';
 @import '../../../components/banner/style';
 @import '../../../components/footer/style';

 .academic-paper-wrapper {
     width: 1200px;
     margin: auto;
     margin-top: 30px;

    .academic-paper-top-info {
        margin-top: 30px;
        margin-bottom: 70px;

        .top-info-title {
            font-family: PingFangTC-Semibold;
            font-size: 28px;
            color: #000;
            letter-spacing: 0;
            line-height: 36px;
            margin-bottom: 12px;
        }

        .top-info-meta-info {
             display: flex;
             justify-content: flex-start;
             margin-bottom: 20px;

            .meta-date {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #000;
                font-weight: 600;
                line-height: 24px;
                margin-right: 10px;
            }

            .meta-right {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #666;
                line-height: 24px;
                margin-right: 5px;
            }

            .meta-conf-name {
                font-weight: 600;
                color: #000;
            }

            .meta-point {
                margin-right: 10px;
                color: #666;
            }
        }

        .top-info-introduce {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #000;
            letter-spacing: 0;
            text-align: justify;
            margin-bottom: 30px;
            line-height: 26px;
            overflow: hidden;
            position: relative;

            .take-all {
                color: #172cca;
                cursor: pointer;
                display: none;
            }
        }

        .show-all-introduce {
            position: absolute;
            right: 0;
            top: 78px;
            width: 102px;
            padding-left: 3px;
            display: flex;
            justify-content: space-between;
            background-color: #fff;
            background-image: linear-gradient(to right, rgba(255,255,255,1) 0% , rgba(255,255,255,1) 3%);

            .extend-operate {
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #172cca;
                letter-spacing: 0;
                line-height: 26px;
                cursor: pointer;
            }
        }

        .top-info-resource-link {
            margin-bottom: 20px;

            .ant-btn {
                margin-right: 12px;
                height: 36px;
                border-radius: 0;
            }

            a:nth-child(1) .ant-btn {
                border: 1px solid #2932e1;
                color:  #2932e1;
            }

            a:nth-child(2) .ant-btn {
                box-shadow: none;
            }

            .check {
                width: 16px;
                height: 16px;
                line-height: 16px;
                background-size: contain;
                margin-right: 4px;
                position: relative;
            }

            .check-code {
                background-image: url('./../../academicEcology/images/check-code.svg');
                transition: background-image .1s linear;
                top: 3px;
            }

            .check-article {
                background-image: url('./../../academicEcology/images/pdf.svg');
                top: 3px;
            }

            a:nth-child(1) .ant-btn:hover {
                background-color: #2932e1;

                span {
                    color: #fff;
                }

                .check-article {
                    background-image: url('./../../academicEcology/images/pdf-white.svg');
                    top: 3px;
                }
            }

            a:nth-child(1) .ant-btn:active {
                background-color: #1434c2;
            }

            a:nth-child(2) .ant-btn:hover {

                span {
                    color: #2932e1;
                }

                .check-code {
                    background-image: url('./../../academicEcology/images/check-code-blue.svg');
                    top: 3px;
                }
            }

            a:nth-child(2) .ant-btn:active {
                background-color: #eef2ff;
            }
        }

        .disabled-icon {

            .ant-popover-disabled-compatible-wrapper .ant-btn .check-code {
                 
                background-image: url('./../../academicEcology/images/check-code-grey.svg');
                top: 3px;
            }
        }

        .top-info-keyword {
            display: flex;
            justify-content: flex-start;

            .keyword-label {
                font-family: PingFangTC-Regular;
                font-size: 16px;
                color: #333;
                letter-spacing: 0;
                margin-right: 12px;
            }

            .keyword-item {
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #666;
                letter-spacing: 0;
                text-align: center;
                line-height: 24px;
                height: 24px;
                background-color: #f5f5f5;
                margin-right: 8px;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }


    .academic-paper-open-code {
        margin-bottom: 70px;
        
        .open-code-title {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #000;
            letter-spacing: 0;
            line-height: 32px;
            padding-bottom: 20px;
            margin-bottom: 32px;
            border-bottom: 1px solid #d8d8d8;
        }

        .open-code-meta-info {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 22px;

            .meta-info-icon {
                width: 20px;
                height: 20px;
                background-image: url('./../../academicEcology/images/open-code-github.svg');
                background-size: contain;
                margin-right: 8px;
            }

            .meta-info-path {
                font-family: PingFangTC-Medium;
                font-size: 16px;
                color: #333;
                letter-spacing: 0;
                margin-right: 20px;
            }

            .meta-info-official {
                background: #f5f5f5;
                border: 1px solid #dadcdf;
                padding: 0 8px 0 8px;

                .official-icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background-image: url('./../../academicEcology/images/Official.svg');
                    margin-right: 4px;
                    position: relative;
                    top: 4px;
                }

                .official-word {
                    font-family: PingFangSC-Regular;
                    font-size: 14px;
                    color: #000;
                    letter-spacing: 0;
                    line-height: 22px;
                }
            }
        }

        .open-code-content {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #000;
            letter-spacing: 0;
            text-align: justify;
            line-height: 26px;
            margin-bottom: 30px;
        }

        .open-code-link-wrapper {

            .link-icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url('./../../academicEcology/images/link.svg');
                position: relative;
                top: 3px;
                margin-right: 6px;
            }
        }
    }

    .academic-paper-activity {
        margin-bottom: 120px;

        .activity-title {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #000;
            letter-spacing: 0;
            line-height: 32px;
            padding-bottom: 20px;
            margin-bottom: 32px;
            border-bottom: 1px solid #d8d8d8;
        }

        .activity-card-wrapper {
            overflow: hidden;

            .activity-card {
                float: left;
                width: 284px;
                margin-right: 21px;
                margin-bottom: 20px;
                transform: scale(1);
                .activity-card-image {
                    width: 284px;
                    height: 160px;
                    background-size: contain;
                }

                .activity-card-content {
                    border: 1px solid #dadcdf;
                    border-top: none;
                    border-radius: 0 0 0 2px;
                    height: 135px;
                    padding: 30px 40px 25px 30px;
                    margin-bottom: 3px;

                    .activity-card-title {
                        font-family: PingFangSC-Semibold;
                        font-size: 20px;
                        color: #000;
                        letter-spacing: 0;
                        line-height: 28px;
                        margin-bottom: 30px;

                        a {
                            color: #000;
                        }
                    }
                }
            }

            .activity-card:hover {
                .activity-card-content {
                    box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.05);
                }
                
                .activity-card-image {
                    cursor: pointer;
                }

                .activity-card-title {
                    color: #515eed;
                    cursor: pointer;

                    a {
                        color: #515eed;
                    }
                }
            }

            .activity-card:nth-child(4n) {
                margin-right: 0;
            }

            .activity-card:nth-child(3) {
                .activity-card-image {
                    border: 1px solid #dadcdf;
                    border-bottom: none;
                }
            }
        }
    }
 }
@primary-color: #2932E1;
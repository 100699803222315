/**
 * @file paddlehub 通用图片 demo样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../../components/video-modal/style';

.video-demo {
    margin-top: 22px;
    position: relative;

    &-right-background {
        background: #fafafa;
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 360px;
        z-index: -1;
    }

    &-right {
        float: right;
        width: 360px;
    }

    &-main {
        margin-right: 360px;
    }

    &-result {
        background: @primary-color;
        color: #fff;
        display: inline-block;
        float: right;
        min-width: 219px;
        padding: 17px 12px;

        &-title {
            font-size: 16px;
            margin-bottom: 14px;
        }

        table {
            width: 100%;
        }

        td {
            display: table-cell;
            height: 32px;
        }

        &-row1 {
        }

        &-row2 {
            width: 120px;
        }

        &-row3 {
        }

        &-progress {
            padding: 0 10px;

            &-inner {
                background: #fff;
                min-width: 1px;
                height: 4px;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                width: 0;
            }
        }
    }

    &-img {
        background-color: #343434;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 524px;
        position: relative;
    }

    &-video-player {
        bottom: 85px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &-img-input {
        //background-color: rgba(0, 0, 0, .5);
        background-color: #000;
        bottom: 0;
        left: 0;
        padding: 20px;
        position: absolute;
        right: 0;
        text-align: right;

        &-info {
            color: #ccc;
            float: left;
            font-size: 11.8px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 38px;
        }

        &-upload {
            float: right;
        }
    }

    &-img-gallery {
        background: #ebebeb;
        height: 130px;
        padding: 20px 10px;
        overflow-x: auto;
        overflow-y: hidden;

        &-item-wrap {
            display: inline-block;
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

        &-item {
            background-color: #c0c0c0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: inline-block;
            height: 90px;
            margin: 0 10px;
            cursor: pointer;
            width: 144px;
        }

        &-item-selected {
            border: 2px solid #2932e1;
        }
    }


    &-json {
        .ant-collapse-header {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            padding: 15px 20px;
        }

        .ant-collapse-content-box {
            padding: 0;
        }

        &-body {
            height: 603px;
            overflow: auto;
            padding: 16px;
        }

        &-list-num-1 &-body {
            height: 603px;
        }

        &-list-num-2 &-body {
            height: 556px;
        }

        &-list-num-3 &-body {
            height: 509px;
        }

        &-list-num-4 &-body {
            height: 462px;
        }

        &-table {
            width: 100%;

            td {
                padding: 5px;
            }
        }

        &-body-content {
            background: inherit;
            display: inline-block;
            margin: 0;
            padding: 0;
            overflow: visible;
        }
    }
}

@primary-color: #2932E1;
.uploadAudio {
    width: 100%;
    height: 231px;
    .uploadAudio_wrapper {
       width: 100%;
       height: 231px;
       padding-top: 47px;
       box-sizing: border-box;
        .uploadAudio_box {
            // margin-top: 47px;
            margin-bottom: 16px;
            width: 100%;
            display: flex;
            justify-content: center;
            .uploadAudio_box_img {
                width: 40px;
                height: 40px;
                background: url("../../../../image/上传音频.png") no-repeat;
                background-size:cover;  
            };
        };
       .uploadAudio_upload{
           margin-bottom: 16px;
           width: 100%;
           display: flex;
           justify-content: center;
            .uploadAudio_upload_btn{
                width: 100px;
                height: 40px;
                background: #2932E1;
                border-radius: 4px;
                span {
                    font-family: PingFangSC-Medium;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    font-weight: 500;
                };
            };
       };
        .uploadAudio_hint {
            width: 100%;
            text-align: center;
            height: 22px;
            font-family: 
            PingFangSC-Regular;
            font-size: 14px;
            color: #999999;
            letter-spacing: 0;
            line-height: 22px;
            font-weight: 400;
            margin-bottom: 49px;
        };
    };
};
@primary-color: #2932E1;
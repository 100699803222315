.p-x-ai-cloud-fast-start {
  width: 1200px;
  margin: 0 auto;
}
.p-x-ai-cloud-fast-start::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-cloud-fast-start-title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 60px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules-wrapper {
  display: flex;
  justify-content: center;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules-wrapper::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules {
  display: flex;
  margin-bottom: 60px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box {
  display: flex;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box:nth-of-type(4) .p-x-ai-cloud-fast-start-module {
  margin-right: 0px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box .p-x-ai-cloud-fast-start-module {
  width: 64px;
  height: 90px;
  margin-right: 58px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box .p-x-ai-cloud-fast-start-module .p-x-ai-cloud-fast-start-module-img {
  height: 54px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box .p-x-ai-cloud-fast-start-module .p-x-ai-cloud-fast-start-module-img .p-x-ai-cloud-fast-start-module-img-box {
  width: 54px;
  height: 54px;
  object-fit: contain;
  display: block;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box .p-x-ai-cloud-fast-start-module-title {
  height: 24px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-modules .p-x-ai-cloud-fast-start-modules-box .p-x-ai-cloud-fast-start-module-line {
  width: 156px;
  height: 1px;
  background: #cccccc;
  margin: 28px 58px 0px 0px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn {
  display: flex;
  justify-content: center;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn .ant-btn-primary:focus {
  color: #FFFFFF;
  background: #1823BA !important;
  border-color: #1823BA !important;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn-skip {
  margin-right: 16px;
  background: #2932E1;
  width: 120px;
  height: 40px;
  border-radius: 20px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn-skip span {
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn-skip:hover {
  background: #5156ED;
  border: 1px solid #5156ed;
  border-radius: 20px;
}
.p-x-ai-cloud-fast-start .p-x-ai-cloud-fast-start-btn-skip:disabled {
  background: #C5D0FF;
  border: none;
}

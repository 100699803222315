/**
 * @file paddlehub demo 词法分析
 * @author FengGuang(fengguang01@baidu.com)
 */
@import "components/lexical-input/style";
@import "components/model-info/style";
@import "components/text-result/style";

.lexical-demo-input-label {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 22px;

    &-title {
    }

    &-link {
        color: @primary-color;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        -webkit-text-decoration-skip: objects;

        &:hover {
            color: #515eed;
        }

        &:active {
            color: #515eed;
        }
    }
}

.lexical-demo-input-wrap {
    margin-top: 16px;
}

.lexical-demo-result {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 22px;
}

.lexical-demo-result-err {
    background-color: #fff;
    box-sizing: border-box;
    color: #666;
    font-size: 16px;
    height: 76px;
    line-height: 76px;
    margin-top: 40px;
    padding-left: 30px;
}

@primary-color: #2932E1;
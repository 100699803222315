/**
 * @file paddle hub 列表页菜单样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-hub-menu {
    background: #fff;
    border-right: 0;
    height: 100%;
    max-height: calc(100vh - 60px);
    overflow-y: hidden;
    width: 100%;
    z-index: 10;

    &:hover {
        overflow-y: auto;
    }

    .ant-menu {
        border-right: none;
        font-weight: 400;

        .ant-menu-item {
            margin: 0;
            padding-left: 10px;
        }

        .ant-menu-item:active {
            background: transparent;
        }

        .ant-menu-item-selected {
            background: transparent;
            font-weight: 600;
        }

        .ant-menu-item-group {
            margin-top: 20px;
        }

        .ant-menu-item-group-list .ant-menu-submenu-title {
            padding-left: 10px;
        }

        .ant-menu-item-group-title {
            color: rgba(0, 0, 0, .3);
            padding-left: 10px;
        }

        .ant-menu-item:hover:not(.ant-menu-item-selected),
        .ant-menu-item-active:not(.ant-menu-item-selected),
        .ant-menu:not(.ant-menu-inline)
        .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: #000;
        }
    }

    .ant-menu-vertical,
    .ant-menu-vertical-left,
    .ant-menu-vertical-right,
    .ant-menu-inline {
        .ant-menu-item::after {
            border-right-width: 2px;
            bottom: 14px;
            left: 0;
            opacity: 0;
            transform: scaleY(0.0001);
            right: unset;
            top: 14px;
        }

        .ant-menu-item-selected.ant-menu-item::after {
            opacity: 1;
            transform: scaleY(1);
        }
    }
}

/**
 * @file paddle 客户案例 视频文字卡片 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../../components/video-cover/style';
@import '../../../components/video-modal/style';

.paddle-vertical-video-text-card {
    &-body {
        border: 1px solid #e5e5e5;
        border-radius: 16px;
        margin-top: 40px;
        overflow: hidden;
    }

    &-video {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 320px;
        position: relative;
    }

    &-video-background-video {
        cursor: pointer;
        opacity: 0;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        z-index: 2;

        &:hover {
            opacity: 1;
        }
    }

    &-video-play-btn {
        background-image: url(./img/play.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: inline-block;
        height: 48px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        z-index: 1;
    }

    &-text {
        padding: 40px 98px 40px 40px;

        &-title {
            color: #000;
            font-size: 22px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 38px;
        }

        &-text {
            color: #666;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 20px;
        }

        &-btns {
            margin-top: 44px;
        }

        &-btn {
            border-color: transparent;
            border-radius: 999999px;
            color: @primary-color;
            padding-left: 32px;
            padding-right: 32px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

/**
 * @file paddle hub 首页模型列表样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-hub-model-list {
	&-pagination-wrap {
		margin-top: 30px;
		text-align: center;
	}
}

.paddle-hub-model-item {
	border: 1px solid rgba(0, 0, 0, .10);
	color: #333;
	display: block;
	margin-bottom: 25px;
	padding: 30px;
	transition: all .2s ease;

	&:last-of-type {
		margin-bottom: 0;
	}

	&:hover {
		box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, .05);
		color: #333;
	}

	&-name {
		color: #000;
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 0;
	}

	&-tag {
		color: #ff7c3f;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 28px;
        margin-left: 8px;
        margin-right: 0;
        margin-bottom: 4px;
        vertical-align: middle;
	}

	&-info {
		color: #000;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 21px;
		margin-top: 15px;
	}

	&-category {
		margin-left: 40px;
	}

	&-network {
		margin-left: 40px;
	}

	&-dataset {
		margin-left: 40px;
	}

	&-summary {
		color: #666;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 24px;
		margin-top: 10px;
		max-height: 24px;
		overflow: hidden;
		position: relative;
	}

	&-summary-ellipsis {
		background: #fff;
		bottom: 0;
		display: inline-block;
		position: absolute;
		right: 0;
	}

	&-more-info {
		color: #666;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		margin-top: 10px;
		line-height: 22px;

		& > * {
			margin-right: 20px;
		}

		&-recommendation-number {
			color: #000;
			font-weight: 500;
		}
	}
}

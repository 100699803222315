/**
 * @file paddlehub demo 新冠肺炎医疗影像 用户须知弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */

.medical-img-modal {
    .ant-modal-header {
        background: #f5f5f5;
        border-bottom: 0;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        padding: 12px 20px;
    }

    .ant-modal-body {
        padding: 16px 20px;
    }

    .ant-modal-footer {
        border-top: 0;
        padding: 7px 20px 30px 20px;
        text-align: center;
    }

    .ant-modal-footer button + button {
        margin-left: 20px;
    }
}

/**
 * @file paddlehub demo 新冠肺炎医疗影像  病灶分析
 * @author FengGuang(fengguang01@baidu.com)
 */

.medical-img-lesion-analysis-table {
    border-collapse: collapse;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 17px;
    width: 100%;

    thead {
        color: rgba(255, 255, 255, .50);
    }

    th, td {
        border: .5px solid rgba(255, 255, 255, .30);
    }

    th {
        font-weight: 400;
        padding: 6px 10px;
    }

    td {
        padding: 11px 10px;
    }
}

.medical-img-ai-analysis {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
    max-height: 175px;
    overflow-y: auto;
    text-align: left;
}

/**
 * @file paddlehub demo 新冠肺炎医疗影像 上传组件
 * @author FengGuang(fengguang01@baidu.com)
 */

.medical-img-upload {
    & > span,
    .ant-upload {
        display: block;
    }

    &-tips {
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 1;
        padding: 20px 0;
        text-align: center;
    }

    @keyframes medical-img-upload-turn {
        0% {
            -webkit-transform: rotate(0deg);
        }
        25% {
            -webkit-transform: rotate(90deg);
        }
        50% {
            -webkit-transform: rotate(180deg);
        }
        75% {
            -webkit-transform: rotate(270deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    &-loading {
        animation: medical-img-upload-turn 3s linear infinite;
        background-image: url('../../image/loading.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        height: 16px;
        margin: 3px;
        width: 16px;
        vertical-align: top;
    }

    &-input {
        background-color: #2a31ff;
        //background-image: linear-gradient(90deg, #3f81ec 0%, #0057ff 69%, #2a31ff 100%);
        background-image: url('../../image/uploadbtn.png');
        background-size: 100% 100%;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        height: 50px;
        letter-spacing: 0;
        width: 100%;

        &:hover,
        &:focus {
            background-color: #2a31ff;
            background-image: url('../../image/uploadbtnhover.png');
            background-size: 100% 100%;
        }
    }
}

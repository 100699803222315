.p-x-ai-application-scenario {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 80px;
}
.p-x-ai-application-scenario::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-application-scenario-title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules {
  display: flex;
  margin: 0 auto;
  min-height: 360px;
  position: relative;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-menu {
  background: #f5f6f9;
  float: none;
  white-space: nowrap;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-menu-item {
  color: #666;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  padding: 16px 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-menu-item:hover {
  color: #515eee;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-menu-item-selected {
  background-color: #2932e1;
  color: #fff;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-menu-item-selected:hover {
  color: #fff;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-body {
  flex: auto;
  padding-left: 60px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 24px;
  padding-bottom: 24px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-body {
  margin-right: 340px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-title {
  height: 28px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
  margin-bottom: 12px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-context::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-context-descContent {
  display: flex;
  margin-bottom: 10px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-context-descContent::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-context-descContent-title {
  min-width: 40px;
  height: 28px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  margin-right: 4px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-context-descContent-desc {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 400;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-img {
  background-color: #e5e5e5;
  float: right;
  height: 168px;
  width: 280px;
}
.p-x-ai-application-scenario .p-x-ai-application-scenario-modules-item-img img {
  height: 100%;
  width: 100%;
}

/**
 * @file paddlehub demo 新冠肺炎医疗影像 自定义message
 * @author FengGuang(fengguang01@baidu.com)
 */
.message-success {
    .ant-message-notice-content {
        background: #2d3958;
        color: #fff;
    }

    .anticon.anticon-check-circle {
        color: #0057ff;
    }
}

@import '../../style/global';
@import '../../components/header/style';
@import '../../components/footer/style';
@import '../ppde/components/ppde-header/style';

.paddle-header a {
    text-decoration: none;
    color: #fff !important;
}

@primary-color: #2932E1;
/**
 * @file 视频封面
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-video-cover {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;
}

.paddle-video-cover-hover-autoplay {
    cursor: pointer;
}

/**
 * @file 视频封面
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: relative;

	.vjs-big-play-button {
		display: none;
	}
}

.paddle-video-hover-autoplay {
	cursor: pointer;
}

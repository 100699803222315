.p-x-ai-core-advantage {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 80px;
}
.p-x-ai-core-advantage::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-core-advantage-title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
}
.p-x-ai-core-advantage-modules {
  display: flex;
  flex-wrap: wrap;
}
.p-x-ai-core-advantage-modules::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-core-advantage-modules .p-x-ai-core-advantage-module {
  width: 300px;
  height: 185px;
  background: #FFFFFF;
  padding: 26px 50.5px 17px 49.5px;
  box-sizing: border-box;
}
.p-x-ai-core-advantage-modules .p-x-ai-core-advantage-module-img {
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.p-x-ai-core-advantage-modules .p-x-ai-core-advantage-module-img-box {
  width: 47px;
  height: 47px;
  object-fit: contain;
  display: block;
}
.p-x-ai-core-advantage-modules .p-x-ai-core-advantage-module-title {
  height: 28px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #000000;
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
}
.p-x-ai-core-advantage-modules .p-x-ai-core-advantage-module-desc {
  height: 44px;
  opacity: 0.6;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
}

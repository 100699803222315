/**
 * @file 垂直省略号
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

.vertical-ellipsis-wrap {
    height: 100%;
    position: relative;
    text-align: justify;
}

.vertical-ellipsis-inner {
    height: 100%;
    overflow: hidden;
}

.vertical-ellipsis-ellipsis {
    bottom: 0;
    left: 100%;
    position: absolute;
}

@primary-color: #2932E1;
.voice_functionDemo {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.voice_functionDemo .voice_functionDemo_title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}
.voice_functionDemo .voice_functionDemo_functionList {
  width: 100%;
}
.voice_functionDemo .voice_tabs {
  margin-top: 15px;
}
.voice_functionDemo .voice_tabs > .ant-tabs-nav {
  margin-bottom: 20px;
}
.voice_functionDemo .voice_tabs > .ant-tabs-nav::before {
  content: none;
}
.voice_functionDemo .voice_tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}
.voice_functionDemo .voice_tabs > .ant-tabs-nav .ant-tabs-tab {
  font-size: 20px;
}
.voice_functionDemo .voice_tabs > .ant-tabs-nav .ant-tabs-nav-list {
  margin-right: -32px;
  flex: none;
}
.voice_hint {
  margin-top: 13px;
  width: 1200px;
  margin: 0 auto;
  height: 22px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #E64552;
  line-height: 22px;
  font-weight: 400;
}

/**
 * @file paddle hub 搜索排行榜列表样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-hub-chart-list {
}

.paddle-hub-chart {
	border: 1px solid #dadcdf;
	margin-bottom: 20px;

	&-title {
		background: #f5f5fe;
		color: #0b0504;
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		padding: 18px 20px;
	}

	&-body {
		color: #666;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0;
		padding: 10px 20px 15px 20px;
		text-align: justify;
	}

	&-line {
		color: #666;
		margin-top: 4px;
		display: flex;

		&:hover {
			color: #666;
		}

		& > * {
			flex: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		& > *:first-of-type {
			flex: none;
			width: 18px;
		}

		& > *:last-of-type {
			flex: none;
			max-width: 60%;
		}
	}

	&-item-red {
		color: #ea2e2e;
	}
}

.p-x-ai-exchange-and-cooperation {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.p-x-ai-exchange-and-cooperation::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content {
  display: flex;
  flex-wrap: wrap;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module {
  width: 588px;
  min-height: 170px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  margin-right: 24px;
  margin-bottom: 10px;
  padding: 27px 44px 24px 45px;
  box-sizing: border-box;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module:hover {
  box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.05);
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module:nth-of-type(2) {
  margin-right: 0px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module:nth-of-type(4) {
  margin-right: 0px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module:nth-of-type(6) {
  margin-right: 0px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-title {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: 12px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-desc {
  font-family: PingFangSC-Regular;
  min-height: 48px;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 9px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper {
  display: flex;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper .my-popover .ant-popover-inner-content {
  padding: 0px 0px !important;
  color: rgba(0, 0, 0, 0.85);
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper .my-popover div div:nth-of-type(2) div {
  padding: 0px !important;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper .content-module-jump {
  cursor: pointer;
  height: 22px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #2932E1;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 500;
  margin-right: 16px;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper .content-module-jump-hover:hover {
  opacity: 0.9;
}
.p-x-ai-exchange-and-cooperation .p-x-ai-exchange-and-cooperation-content .p-x-ai-exchange-and-cooperation-content-module .content-module-jump-wrapper .content-module-jump-disabled:hover {
  cursor: not-allowed;
  color: #C5D0FF;
}

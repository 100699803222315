/**
 * @file paddlehub demo ai写作面板 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../tag-item/style';

.ai-writing-show-pane {
    &-exampletag-label {
        float: left;
        line-height: 32px;
        margin: 6px 0;
    }

    &-exampletag-right {
        margin-left: 60px;
    }

    &-form {
        margin-top: 30px;
    }

    &-input {
        margin-right: 20px;
        width: 420px;
    }

    &-switch-btn {
        color: @primary-color;
        cursor: pointer;
        float: right;

        .anticon {
            margin-right: 10px;
        }
    }

    &-errorinfo {
        color: #ea2e2e;
        font-size: 14px;
        font-weight: 400;
        height: 22px;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 4px;
    }

    &-result-wrap {
        display: table;
        margin-top: 4px;
        min-height: 276px;
        width: 100%;
    }

    &-result {
        background-image: url(./image/result-bg.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        display: table-cell;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 4px;
        line-height: 28px;
        vertical-align: middle;
        text-align: center;
    }

    &-result-inner {
        padding: 20px;
    }
}

/**
 * @file paddlehub demo 词法分析输入框样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.lexical-demo-input {

    &-textarea-wrap {
        border: 1px solid rgba(0, 0, 0, 0.10);
        border-radius: 16px;
        height: 194px;
        position: relative;
    }

    &-textarea {
        border: 0;
        border-radius: 16px;
        color: #666;
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        outline: none;
        line-height: 28px;
        padding: 20px;
        text-align: left;
        width: 100%;
    }

    &-length-text-wrap {
        bottom: 20px;
        left: 20px;
        position: absolute;
    }

    &-length-text {
        color: #a1a1a1;
        font-size: 13px;
    }

    &-length-text-num {
        color: #ff851a;
        margin: 0 2px 0 2px;
    }
}



/**
 * @file paddle 学术生态详情 公共样式
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
html,
body {
  min-width: 1400px;
  margin: 0;
  padding: 0;
}
.page {
  font-size: 14px;
  font-weight: 400;
}
.paddle-context-wrap {
  position: relative;
}
.paddle-context-link-more {
  bottom: 0;
  position: absolute;
  right: 0;
}
.paddle-context-link-more a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.paddle-context-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  margin-top: 54px;
}
.paddle-context-h3 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 15px;
}
.paddle-context-h3 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-context-h4 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}
.paddle-context-h4 a {
  color: #2932E1;
  margin-left: 8px;
}
.clear-both:after {
  content: '';
  display: block;
  line-height: 0;
  clear: both;
}
.link-button {
  border: none;
  display: inline;
  margin: 0;
  font-weight: 500;
  padding: 0;
  color: #2932E1;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.link-button:active,
.link-button:hover {
  text-decoration: none;
  outline: 0;
}
.link-button:hover {
  color: #515eed;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-head-search-ac {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 300px;
}
.paddle-head-search-ac .ant-input-prefix {
  color: #2932E1;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 0px;
}
.paddle-head-search-ac.ant-select-open {
  width: 800px;
}
.paddle-head-search-ac .ant-input {
  height: 22px;
  line-height: 22px;
}
.paddle-head-search-ac .ant-input-affix-wrapper {
  margin-bottom: 5px;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless .ant-input-prefix {
  color: #fff;
}
.paddle-head-search-ac .ant-input-affix-wrapper-focused {
  border-radius: 0 !important;
}
.paddle-head-search-ac .ant-input-borderless {
  color: #fff;
}
.paddle-head-search-ac .ant-input-borderless::-webkit-input-placeholder,
.paddle-head-search-ac .ant-input-borderless::placeholder {
  color: rgba(254, 253, 253, 0.5);
}
.paddle-head-search-ac-overlay {
  padding: 0 0 4px 0;
}
.paddle-head-search-ac-overlay-ellipsis {
  display: inline-block;
  flex: none;
  overflow: hidden;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.paddle-head-search-ac-overlay .ant-select-item {
  padding: 5px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item,
.paddle-head-search-ac-overlay .ant-select-item a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay .ant-select-item-group {
  padding: 23px 40px 8px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item-group,
.paddle-head-search-ac-overlay .ant-select-item-group a {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay-more {
  color: #2932E1;
  padding: 12px 40px;
}
.paddle-head-search-ac-overlay-divider {
  border-top: 1px solid #dadcdf;
  margin: 24px 40px 0 40px;
}
.paddle-head-search-ac-overlay-item {
  align-items: center;
  display: flex;
}
.paddle-head-search-ac-overlay-item-title {
  color: #666;
  display: inline-block;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-head-search-ac-overlay-item-title em {
  color: #000;
  font-weight: 700;
}
.paddle-head-search-ac-overlay-item-divider {
  border-left: 1px solid #dadcdf;
  display: inline-block;
  height: 14px;
}
.paddle-head-search-ac-overlay-item-type {
  padding-left: 18px;
}
.paddle-head-search-ac-overlay-loading-wrap {
  margin: 24px 40px;
}
.paddle-header-menu-popup .ant-select-dropdown {
  border-radius: 0;
}
.paddle-head-search-ac-overlay-type2 .ant-select-item-group,
.paddle-head-search-ac-overlay-type2 .ant-select-item-group a {
  color: #000;
}
.paddle-header-wrap {
  font-size: 14px;
  font-weight: 400;
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  z-index: 999;
}
.paddle-header {
  font-weight: 400;
  height: 60px;
  margin: 0 30px;
  min-width: 1200px;
  overflow: hidden;
}
.paddle-header-background {
  background-color: #1527c2;
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
  color: #fff;
}
.paddle-header .ant-menu-item-selected a {
  color: #fff;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 60px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  width: 100%;
}
.paddle-header-logo {
  margin-top: 13px;
  width: 96px;
}
.paddle-header-menu {
  display: inline-block;
  padding: 16px 0 16px 33px;
  vertical-align: top;
  min-width: 697px;
}
.paddle-header-right {
  float: right;
  height: 60px;
}
.paddle-header-right-feedback {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}
.paddle-header-right-feedback-close {
  position: relative;
  top: 7px;
  left: calc(100% - 22px);
}
.paddle-header-right-feedback-close .feedback-close-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  background-image: url('../../../images/headerfeedback/icon-close.png');
  background-size: cover;
}
.paddle-header-right-feedback-button {
  cursor: pointer;
  position: absolute;
  width: 92px;
  height: 32px;
  left: 10%;
  top: 50%;
  background-image: url('../../../images/headerfeedback/button.png');
  background-size: cover;
}
.paddle-header-right-feedback-button span {
  margin-left: 10px;
  text-align: center;
  font-size: 12.6px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 40px;
}
.paddle-header-right-feedback-popover .ant-popover-content {
  position: relative;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin-top: 4px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-arrow {
  top: -4px;
  border: #2932e1;
  background-color: #2932e1;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner {
  width: 314px;
  height: 116px;
  background-image: url('../../../images/headerfeedback/bg.png');
  background-size: cover;
  border-radius: 0px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  border-bottom: 0px;
}
.paddle-header-right-feedback-icon {
  width: 40px;
  height: 40px;
}
.paddle-header-right-language {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
}
.paddle-header-right-language span {
  margin: 0 5px;
  cursor: pointer;
}
.paddle-header-right-language span:hover {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-zh {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-en {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
}
.paddle-header-search-wrap {
  display: inline-block;
  line-height: 60px;
  margin-right: 10px;
  width: 280px;
  vertical-align: top;
}
.paddle-header-links-menu {
  display: inline-block;
  height: 60px;
  margin: 0 -17px 0 17px;
  padding: 16px 0 16px 0;
  vertical-align: top;
}
.paddle-header .ant-avatar .anticon {
  margin-right: 0;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 14px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu:nth-last-child(1) {
  margin: 0 16px;
  padding: 0;
}
.paddle-header .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline),
.paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-item:hover > a,
.paddle-header .ant-menu-item-active > a,
.paddle-header .ant-menu:not(.ant-menu-inline) > a,
.paddle-header .ant-menu-submenu-open > a,
.paddle-header .ant-menu-submenu-active > a,
.paddle-header .ant-menu-submenu-title > a,
.paddle-header .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.paddle-header .ant-menu-item,
.paddle-header .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-item.paddle-header-user-avatar-menu-item,
.paddle-header .ant-menu-submenu.paddle-header-user-avatar-menu-item {
  border-bottom: 3px solid transparent;
  height: 27px;
  margin-top: -2px;
  vertical-align: top;
}
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
}
.paddle-header .ant-menu-item a {
  color: #fff;
}
.paddle-header .ant-menu-item a:hover {
  color: #fff;
}
.paddle-header .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.paddle-header-item-group-list .ant-menu-item,
.paddle-header-item-group-list .ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  height: 27px;
}
.paddle-header-menu-popup {
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 60px;
  z-index: 1499;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item {
  font-weight: 500;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-active {
  background: #f7f7f7;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item,
.paddle-header-menu-popup .ant-menu .ant-menu-submenu {
  margin: 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-item,
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-submenu {
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header-submenu-popup-large {
  max-width: 100%;
  right: 0;
}
.paddle-header-submenu-popup-large .ant-menu {
  display: flex;
  padding: 22px 0 42px 55px;
  text-align: left;
}
.paddle-header-submenu-popup-large .ant-menu > * {
  flex-grow: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group {
  float: left;
  padding: 0 15px;
  width: 225px;
  z-index: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-title {
  border-bottom: 1px solid #e9eaec;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  margin: 0 10px;
  padding: 0;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-list {
  margin-top: 12px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item:hover {
  background: #f7f7f7;
  color: #1434c2;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title {
  width: 315px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-title {
  display: none;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item {
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover),
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover) > a {
  color: #000;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-list {
  margin-top: 0;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-right-icon {
  margin-left: 10px;
  transform: scale(0.65, 0.8);
}
.paddle-header-submenu-popup-large .ant-menu-hidden {
  display: none;
}
.paddle-header-submenu-popup-large-tag {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
.paddle-header-submenu-popup-large-tag.red {
  color: #ff3912;
}
.paddle-header-submenu-popup-large-tag.blue {
  color: #1a73e8;
}
.paddle-header-submenu-popup-large-tag.green {
  color: #00b359;
}
.paddle-header-submenu-popup-large-bg {
  background: #fff;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 21%;
  z-index: 0;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 60px;
  height: 60px;
  margin-left: -2px;
}
.paddle-header-links-link:after {
  display: block;
  content: attr(title);
  font-weight: 500;
  visibility: hidden;
  height: 0;
  margin-bottom: -1px;
  overflow: hidden;
}
@media screen and (max-width: 1260px) {
  .paddle-header {
    margin: 0 auto;
    width: 1400px;
  }
}
/**
 * @file 首页banner样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-banner {
  padding-top: 50px;
  position: relative;
}
.paddle-banner-container {
  margin: 0 auto;
  max-width: 1400px;
  padding-bottom: 40px;
  position: relative;
}
.paddle-banner-divider {
  border-bottom: 1px solid #d1d1d1;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-title {
  color: #000;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 57px;
}
.paddle-banner-subtitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 15px;
  text-align: justify;
}
.paddle-banner-btn-group {
  bottom: 15%;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-banner-btn {
  border-radius: 999999px;
  font-size: 24px;
  color: #1434c2;
  height: 50px;
  letter-spacing: 1px;
  width: 180px;
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #000;
  display: block;
  min-height: 403px;
}
.paddle-footer,
.paddle-footer a {
  color: #999;
}
.paddle-footer-container {
  max-width: 1400px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0;
}
.paddle-footer-group {
  width: 191px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  font-size: 18px;
}
.paddle-footer-item-list {
  float: left;
  margin-top: 25px;
}
.paddle-footer-item,
.paddle-footer-item a {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}
.paddle-footer-qr-wrap {
  float: right;
  margin-bottom: -11px;
  margin-right: -55px;
}
.paddle-footer-qr-item {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 180px;
  text-align: center;
}
.paddle-footer-qr-context {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.paddle-footer-qr-context-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-title {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  text-align: center;
  vertical-align: top;
}
.paddle-footer-qr-subtitle {
  font-size: 12px;
}
.paddle-footer-bottom {
  background: #000;
  border-top: 1px solid #242424;
  color: #999;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.paddle-footer .beian {
  margin-left: 100px;
}
.paddle-footer .footer-mark-number {
  margin-left: 80px;
}
.paddle-footer .record-link-style:hover {
  color: rgba(153, 153, 153, 0.8);
}
.academic-paper-wrapper {
  width: 1200px;
  margin: auto;
  margin-top: 30px;
}
.academic-paper-wrapper .academic-paper-top-info {
  margin-top: 30px;
  margin-bottom: 70px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-title {
  font-family: PingFangTC-Semibold;
  font-size: 28px;
  color: #000;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 12px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-meta-info {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-meta-info .meta-date {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
  margin-right: 10px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-meta-info .meta-right {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-right: 5px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-meta-info .meta-conf-name {
  font-weight: 600;
  color: #000;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-meta-info .meta-point {
  margin-right: 10px;
  color: #666;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-introduce {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  text-align: justify;
  margin-bottom: 30px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-introduce .take-all {
  color: #172cca;
  cursor: pointer;
  display: none;
}
.academic-paper-wrapper .academic-paper-top-info .show-all-introduce {
  position: absolute;
  right: 0;
  top: 78px;
  width: 102px;
  padding-left: 3px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 3%);
}
.academic-paper-wrapper .academic-paper-top-info .show-all-introduce .extend-operate {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #172cca;
  letter-spacing: 0;
  line-height: 26px;
  cursor: pointer;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link {
  margin-bottom: 20px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link .ant-btn {
  margin-right: 12px;
  height: 36px;
  border-radius: 0;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(1) .ant-btn {
  border: 1px solid #2932e1;
  color: #2932e1;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(2) .ant-btn {
  box-shadow: none;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link .check {
  width: 16px;
  height: 16px;
  line-height: 16px;
  background-size: contain;
  margin-right: 4px;
  position: relative;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link .check-code {
  background-image: url('../../academicEcology/images/check-code.svg');
  transition: background-image 0.1s linear;
  top: 3px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link .check-article {
  background-image: url('../../academicEcology/images/pdf.svg');
  top: 3px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(1) .ant-btn:hover {
  background-color: #2932e1;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(1) .ant-btn:hover span {
  color: #fff;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(1) .ant-btn:hover .check-article {
  background-image: url('../../academicEcology/images/pdf-white.svg');
  top: 3px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(1) .ant-btn:active {
  background-color: #1434c2;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(2) .ant-btn:hover span {
  color: #2932e1;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(2) .ant-btn:hover .check-code {
  background-image: url('../../academicEcology/images/check-code-blue.svg');
  top: 3px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-resource-link a:nth-child(2) .ant-btn:active {
  background-color: #eef2ff;
}
.academic-paper-wrapper .academic-paper-top-info .disabled-icon .ant-popover-disabled-compatible-wrapper .ant-btn .check-code {
  background-image: url('../../academicEcology/images/check-code-grey.svg');
  top: 3px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-keyword {
  display: flex;
  justify-content: flex-start;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-keyword .keyword-label {
  font-family: PingFangTC-Regular;
  font-size: 16px;
  color: #333;
  letter-spacing: 0;
  margin-right: 12px;
}
.academic-paper-wrapper .academic-paper-top-info .top-info-keyword .keyword-item {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666;
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  height: 24px;
  background-color: #f5f5f5;
  margin-right: 8px;
  padding-left: 10px;
  padding-right: 10px;
}
.academic-paper-wrapper .academic-paper-open-code {
  margin-bottom: 70px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #000;
  letter-spacing: 0;
  line-height: 32px;
  padding-bottom: 20px;
  margin-bottom: 32px;
  border-bottom: 1px solid #d8d8d8;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 22px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info .meta-info-icon {
  width: 20px;
  height: 20px;
  background-image: url('../../academicEcology/images/open-code-github.svg');
  background-size: contain;
  margin-right: 8px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info .meta-info-path {
  font-family: PingFangTC-Medium;
  font-size: 16px;
  color: #333;
  letter-spacing: 0;
  margin-right: 20px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info .meta-info-official {
  background: #f5f5f5;
  border: 1px solid #dadcdf;
  padding: 0 8px 0 8px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info .meta-info-official .official-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../../academicEcology/images/Official.svg');
  margin-right: 4px;
  position: relative;
  top: 4px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-meta-info .meta-info-official .official-word {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  line-height: 22px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-content {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000;
  letter-spacing: 0;
  text-align: justify;
  line-height: 26px;
  margin-bottom: 30px;
}
.academic-paper-wrapper .academic-paper-open-code .open-code-link-wrapper .link-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../../academicEcology/images/link.svg');
  position: relative;
  top: 3px;
  margin-right: 6px;
}
.academic-paper-wrapper .academic-paper-activity {
  margin-bottom: 120px;
}
.academic-paper-wrapper .academic-paper-activity .activity-title {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #000;
  letter-spacing: 0;
  line-height: 32px;
  padding-bottom: 20px;
  margin-bottom: 32px;
  border-bottom: 1px solid #d8d8d8;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper {
  overflow: hidden;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card {
  float: left;
  width: 284px;
  margin-right: 21px;
  margin-bottom: 20px;
  transform: scale(1);
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card .activity-card-image {
  width: 284px;
  height: 160px;
  background-size: contain;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card .activity-card-content {
  border: 1px solid #dadcdf;
  border-top: none;
  border-radius: 0 0 0 2px;
  height: 135px;
  padding: 30px 40px 25px 30px;
  margin-bottom: 3px;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card .activity-card-content .activity-card-title {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 30px;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card .activity-card-content .activity-card-title a {
  color: #000;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:hover .activity-card-content {
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.05);
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:hover .activity-card-image {
  cursor: pointer;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:hover .activity-card-title {
  color: #515eed;
  cursor: pointer;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:hover .activity-card-title a {
  color: #515eed;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:nth-child(4n) {
  margin-right: 0;
}
.academic-paper-wrapper .academic-paper-activity .activity-card-wrapper .activity-card:nth-child(3) .activity-card-image {
  border: 1px solid #dadcdf;
  border-bottom: none;
}

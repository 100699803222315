/**
 * @file paddlehub demo 词法分析
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file paddlehub demo 词法分析输入框样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.lexical-demo-input-textarea-wrap {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 194px;
  position: relative;
}
.lexical-demo-input-textarea {
  border: 0;
  border-radius: 16px;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  outline: none;
  line-height: 28px;
  padding: 20px;
  text-align: left;
  width: 100%;
}
.lexical-demo-input-length-text-wrap {
  bottom: 20px;
  left: 20px;
  position: absolute;
}
.lexical-demo-input-length-text {
  color: #a1a1a1;
  font-size: 13px;
}
.lexical-demo-input-length-text-num {
  color: #ff851a;
  margin: 0 2px 0 2px;
}
/**
 * @file paddlehub demo 词法分析模型信息样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.lexical-model-info {
  font-weight: 400;
  margin-top: 10px;
}
.lexical-model-info .ant-row {
  margin-bottom: 10px;
}
.lexical-model-info .ant-row:last-of-type {
  margin-bottom: 0;
}
.lexical-model-info label,
.lexical-model-info .ant-form-item-control {
  font-size: 16px;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.lexical-model-info .ant-form-item-control {
  line-height: 40px;
}
.lexical-model-info .lexical-model-info-select-model {
  min-width: 476px;
}
.lexical-model-info-devresource-item {
  border-right: 1px solid #2932E1;
  padding: 0 8px;
}
.lexical-model-info-devresource-item:last-of-type {
  border-right: 0;
}
.lexical-model-info-devresource-item-disabled,
.lexical-model-info-devresource-item-disabled:hover {
  color: #cecece;
}
/**
 * @file paddlehub demo 词法分析结果展示
 * @author FengGuang(fengguang01@baidu.com)
 */
.lexical-result-cover {
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  min-width: 1200px;
  margin-top: 24px;
  padding: 30px 0 26px 26px;
  border: 1px solid #e0e0e0;
}
.lexical-result-left {
  border-right: 1px solid #d8d8d8;
  box-sizing: border-box;
  float: left;
  font-size: 0;
  margin-bottom: 26px;
  padding-right: 40px;
  width: 893px;
}
.lexical-result-left-title {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 22px;
}
.lexical-result-tips-wrap {
  margin-bottom: -10px;
  margin-right: -10px;
}
.lexical-result-tips {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 48px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 8px 16px;
  position: relative;
}
.lexical-result-tips-mes {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  max-width: 280px;
  min-height: 16px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lexical-result-tips-des {
  color: #999;
  font-size: 13px;
  line-height: 1;
  text-align: center;
}
.lexical-result-tips-des-show {
  color: #fff;
}
.lexical-result-tips-bubble {
  position: absolute;
  z-index: 2;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px 0 16px;
  word-break: keep-all;
  height: 30px;
  cursor: default;
  background-color: #666;
  border-radius: 2px;
  color: #fff;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
}
.lexical-result-tips-triangle {
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #666;
}
.lexical-result-tips-show {
  background-color: #66b3ff;
  color: #fff;
  border-color: #66b3ff;
}
.lexical-result-right {
  border-left: 1px solid #e0e0e0;
  float: left;
  margin-left: -1px;
  padding: 0 40px;
  width: 277px;
}
.lexical-result-right .mes-analyse {
  margin-bottom: 20px;
  font-size: 16px;
}
.lexical-result-right .mes-des-cover {
  background: rgba(212, 216, 251, 0.2);
  border-radius: 4px;
  position: relative;
  padding: 14px 12px 14px 12px;
}
.lexical-result-right .mes-des-cover .mes-des-arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  cursor: pointer;
  width: 20px;
  height: 40px;
  background-image: url('../images/common-icon/leftArray.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25% 25%;
}
.lexical-result-right .mes-des-cover .mes-des-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  height: 40px;
  right: -20px;
  background-image: url('../images/common-icon/rightArray.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25% 25%;
}
.lexical-result-right .mes-des-cover .mes-des-arr-disabled {
  color: #fbf9ee;
}
.lexical-result-right .mes-content {
  margin-bottom: 13px;
  padding: 5px;
  font-size: 14px;
  color: #333333;
  background-color: #e6f2ff;
}
.lexical-result-right .mes-type {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1;
  color: #666;
}
.lexical-result-right .mes-type-in {
  color: #000;
}
.lexical-result-right .mes-divide {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lexical-result-right .mes-select-title {
  padding-bottom: 20px;
  margin-top: 48px;
  font-size: 16px;
}
.lexical-result-right .mes-select-cover {
  overflow: hidden;
}
.lexical-result-right .mes-select-tips-wrap {
  margin-right: -10px;
  overflow: hidden;
}
.lexical-result-right .mes-select-tips {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  font-size: 14px;
  height: 36px;
  line-height: 34px;
  margin: 0 10px 10px 0;
  text-align: center;
  width: 93px;
}
.lexical-result-right .lexical-result-self-style {
  color: #73cfff;
  border-color: #73cfff;
}
.lexical-result-person-type {
  background-color: #ffa666;
  color: #fff;
  border-color: #ffa666;
}
.lexical-result-place-type {
  background-color: #ffc859;
  color: #fff;
  border-color: #ffc859;
}
.lexical-result-time-type {
  background-color: #a1e6a6;
  color: #fff;
  border-color: #a1e6a6;
}
.lexical-result-mechanism-name {
  background-color: #73cfff;
  color: #fff;
  border-color: #73cfff;
}
.lexical-demo-input-label {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 22px;
}
.lexical-demo-input-label-link {
  color: #2932E1;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.lexical-demo-input-label-link:hover {
  color: #515eed;
}
.lexical-demo-input-label-link:active {
  color: #515eed;
}
.lexical-demo-input-wrap {
  margin-top: 16px;
}
.lexical-demo-result {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 22px;
}
.lexical-demo-result-err {
  background-color: #fff;
  box-sizing: border-box;
  color: #666;
  font-size: 16px;
  height: 76px;
  line-height: 76px;
  margin-top: 40px;
  padding-left: 30px;
}

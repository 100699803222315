/**
 * @file paddlehub demo 新冠肺炎医疗影像 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file paddlehub demo 新冠肺炎医疗影像 图表  样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.lung-chart {
  height: 210px;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像 输入框
 * @author FengGuang(fengguang01@baidu.com)
 */
.textarea-wrap {
  position: relative;
}
.textarea {
  background: transparent;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  display: block;
  font-size: 12px;
  min-height: 64px;
  outline: none;
  padding: 10px;
  width: 100%;
}
.textarea-length {
  bottom: 10px;
  position: absolute;
  right: 10px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0;
  line-height: 12px;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像 上传组件
 * @author FengGuang(fengguang01@baidu.com)
 */
.medical-img-upload > span,
.medical-img-upload .ant-upload {
  display: block;
}
.medical-img-upload-tips {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1;
  padding: 20px 0;
  text-align: center;
}
@keyframes medical-img-upload-turn {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.medical-img-upload-loading {
  animation: medical-img-upload-turn 3s linear infinite;
  background-image: url('./image/loading.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  height: 16px;
  margin: 3px;
  width: 16px;
  vertical-align: top;
}
.medical-img-upload-input {
  background-color: #2a31ff;
  background-image: url('./image/uploadbtn.png');
  background-size: 100% 100%;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  height: 50px;
  letter-spacing: 0;
  width: 100%;
}
.medical-img-upload-input:hover,
.medical-img-upload-input:focus {
  background-color: #2a31ff;
  background-image: url('./image/uploadbtnhover.png');
  background-size: 100% 100%;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像 图像
 * @author FengGuang(fengguang01@baidu.com)
 */
.medical-img-main-img-wrap {
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.medical-img-main-img {
  background-color: rgba(0, 0, 0, 0.3);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.medical-img-main-length {
  bottom: 10px;
  position: absolute;
  right: 10px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0;
  line-height: 12px;
}
.medical-img-main-img-gallery-wrap {
  font-size: 0;
  margin-top: 25px;
  overflow-x: auto;
  overflow-y: hidden;
  outline: none;
  padding-bottom: 28px;
}
.medical-img-main-img-gallery-wrap::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  height: 11px;
  width: 11px;
}
.medical-img-main-img-gallery-wrap::-webkit-scrollbar-button {
  display: none;
}
.medical-img-main-img-gallery-wrap::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  height: 7px;
  width: 7px;
}
.medical-img-main-img-gallery {
  display: inline-block;
  margin: 0 -4px;
  padding: 0;
  white-space: nowrap;
}
.medical-img-main-img-gallery-inner {
  display: inline-block;
  overflow: hidden;
  position: relative;
}
.medical-img-main-img-gallery-item {
  background-color: rgba(0, 0, 0, 0.3);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 80px;
  margin: 0 4px;
  width: 80px;
}
.medical-img-main-img-gallery-item-selected {
  border: 1px solid #fff;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像  病灶分析
 * @author FengGuang(fengguang01@baidu.com)
 */
.medical-img-lesion-analysis-table {
  border-collapse: collapse;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  width: 100%;
}
.medical-img-lesion-analysis-table thead {
  color: rgba(255, 255, 255, 0.5);
}
.medical-img-lesion-analysis-table th,
.medical-img-lesion-analysis-table td {
  border: 0.5px solid rgba(255, 255, 255, 0.3);
}
.medical-img-lesion-analysis-table th {
  font-weight: 400;
  padding: 6px 10px;
}
.medical-img-lesion-analysis-table td {
  padding: 11px 10px;
}
.medical-img-ai-analysis {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
  max-height: 175px;
  overflow-y: auto;
  text-align: left;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像 自定义message
 * @author FengGuang(fengguang01@baidu.com)
 */
.message-success .ant-message-notice-content {
  background: #2d3958;
  color: #fff;
}
.message-success .anticon.anticon-check-circle {
  color: #0057ff;
}
/**
 * @file paddlehub demo 新冠肺炎医疗影像 用户须知弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
.medical-img-modal .ant-modal-header {
  background: #f5f5f5;
  border-bottom: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 12px 20px;
}
.medical-img-modal .ant-modal-body {
  padding: 16px 20px;
}
.medical-img-modal .ant-modal-footer {
  border-top: 0;
  padding: 7px 20px 30px 20px;
  text-align: center;
}
.medical-img-modal .ant-modal-footer button + button {
  margin-left: 20px;
}
.medical-img {
  background-color: #233e68;
  background-image: linear-gradient(0deg, #233e68 2%, #0a1431 100%);
  color: #fff;
  margin-top: 30px;
  position: relative;
}
.medical-img-menu {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  display: block;
  font-weight: 500;
  font-size: 26px;
  list-style-type: none;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  padding: 0 15px;
}
.medical-img-menu-item {
  display: inline-block;
  float: left;
  padding: 25px 15px;
}
.medical-img-menu-item-disabled {
  color: rgba(255, 255, 255, 0.2);
  font-weight: 400;
}
.medical-img-body {
  display: flex;
}
.medical-img-left {
  padding: 25px 30px;
  width: 440px;
}
.medical-img-right {
  border-left: 0.5px solid rgba(255, 255, 255, 0.3);
  flex-grow: 1;
  padding: 25px 30px;
}
.medical-img-h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin: 30px 0 18px 0;
  text-align: left;
}
.medical-img-h2-sub {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1;
  margin-left: 8px;
}
.medical-img-right-title {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 30px;
}
.medical-img-right-title::before {
  background: #0057ff;
  content: '';
  display: inline-block;
  height: 18px;
  margin-right: 8px;
  vertical-align: top;
  width: 3px;
}
.medical-img-chart-text {
  color: #fff;
  float: right;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  margin-right: 160px;
  margin-top: 4px;
}
.medical-img-chart-wrap {
  margin: -34px -20px 0 -20px;
}
.medical-img-chart-body-left {
  display: inline-block;
  width: 50%;
}

/**
 * @file 学术生态 搜索框封装
 * @author WanZhongYun(wanzhongyun@baidu.com)
 */

 .academic-search-wrapper {

    .academic-search-input-icon {
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../../images/search.svg');
        display: inline-block;
        height: 14px;
        margin-top: 0;
        width: 14px;
        cursor: pointer;
    }
 }
@primary-color: #2932E1;
/**
 * @file 排序tag样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-sorter {
	cursor: pointer;
	display: inline-flex;
	line-height: 32px;
}

.paddle-sorter-icon {
	display: block;
	font-size: 10px;
	margin-left: 4px;
	margin-top: -3px;

	.anticon {
		display: block;
	}

	.anticon + .anticon {
		margin-top: -2px;
	}

	.paddle-sorter-icon-inner {
		align-items: center;
		display: inline-flex;
		flex-direction: column;
	}

	.anticon-caret-up,
	.anticon-caret-down {
		color: #d6d6d6;
	}

	&.paddle-sorter-icon-down {
		.anticon-caret-down {
			color: #999;
		}
	}

	&.paddle-sorter-icon-up {
		.anticon-caret-up {
			color: #999;
		}
	}
}

/**
 * @file paddlehub demo 新冠肺炎医疗影像 图像
 * @author FengGuang(fengguang01@baidu.com)
 */

.medical-img-main {
    &-img-wrap {
        height: 0;
        padding-bottom: 100%;
        position: relative;
    }

    &-img {
        background-color: rgba(0, 0, 0, .3);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &-length {
        bottom: 10px;
        position: absolute;
        right: 10px;
        font-weight: 400;
        font-size: 12px;
        color: rgba(255, 255, 255, .50);
        letter-spacing: 0;
        line-height: 12px;
    }

    &-img-gallery-wrap {
        font-size: 0;
        margin-top: 25px;
        overflow-x: auto;
        overflow-y: hidden;
        outline: none;
        padding-bottom: 28px;

        &::-webkit-scrollbar {
            background: rgba(255, 255, 255, 0.08);
            border-radius: 6px;
            height: 11px;
            width: 11px;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.60);
            border-radius: 4px;
            height: 7px;
            width: 7px;
        }
    }

    &-img-gallery {
        display: inline-block;
        margin: 0 -4px;
        padding: 0;
        white-space: nowrap;
    }

    &-img-gallery-inner {
        display: inline-block;
        overflow: hidden;
        position: relative;
    }

    &-img-gallery-item {
        background-color: rgba(0, 0, 0, .3);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        height: 80px;
        margin: 0 4px;
        width: 80px;

        &-selected {
            border: 1px solid #fff;
        }
    }
}

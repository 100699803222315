.p-x-ai-exchange-and-cooperation{
    width: 1200px;
    margin: 0 auto; 
    margin-bottom: 100px;
    &::after {
        content: "";
        display: block;
        overflow:hidden;
        visibility:hidden;
        height: 0;
        clear: both;
    };
    .p-x-ai-exchange-and-cooperation-title {
        height: 42px;
        font-family: PingFangSC-Semibold;
        font-size: 30px;
        color: #000000;
        text-align: center;
        font-weight: 600;
        margin-bottom: 40px;
    };
    .p-x-ai-exchange-and-cooperation-content {
        display: flex;
        flex-wrap: wrap;
        &::after {
            content: "";
            display: block;
            overflow:hidden;
            visibility:hidden;
            height: 0;
            clear: both;
        };
        .p-x-ai-exchange-and-cooperation-content-module {
            width: 588px;
            min-height: 170px;
            border: 1px solid rgba(224,224,224,1);
            border-radius: 16px;
            margin-right: 24px;
            margin-bottom: 10px;
            padding: 27px 44px 24px 45px;
            box-sizing: border-box;
            &:hover {
                box-shadow: 4px 8px 12px 0px rgba(0,0,0,0.05);
            };
            &:nth-of-type(2) {
                margin-right: 0px;
            };
            &:nth-of-type(4) {
                margin-right: 0px;
            };
            &:nth-of-type(6) {
                margin-right: 0px;
            };
            .content-module-title {
                font-family: PingFangSC-Semibold;
                font-size: 20px;
                color: #000000;
                letter-spacing: 0;
                font-weight: 600;
                margin-bottom: 12px;
            };
            .content-module-desc {
                font-family: PingFangSC-Regular;
                min-height: 48px;
                font-size: 14px;
                color: #000000;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 9px;
            };
            .content-module-jump-wrapper {
                display: flex;
                .my-popover {
                    .ant-popover-inner-content {
                        padding: 0px 0px !important;
                        color: rgba(0, 0, 0, 0.85);
                    };  
                    div {
                        div {
                            &:nth-of-type(2) {
                                div {
                                    padding: 0px !important;
                                };
                            };
                        };
                    };  
                };
                    
                 
                   
                   
                
                .content-module-jump {
                    cursor: pointer;
                    height: 22px;
                    font-family: PingFangSC-Medium;
                    font-size: 14px;
                    color: #2932E1;
                    letter-spacing: 0;
                    line-height: 22px;
                    font-weight: 500;
                    margin-right: 16px;
                };
                .content-module-jump-hover:hover{
                    opacity: 0.9; 
                }; 
                .content-module-jump-disabled:hover {
                    cursor: not-allowed;
                    color: #C5D0FF ;
                };
               
               
            };
           
        };
    };
  
};
@primary-color: #2932E1;
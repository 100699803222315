/**
 * @file paddlehub demo 新冠肺炎医疗影像 输入框
 * @author FengGuang(fengguang01@baidu.com)
 */
.textarea-wrap {
    position: relative;
}

.textarea {
    background: transparent;
    border: .5px solid rgba(255, 255, 255, .30);
    display: block;
    font-size: 12px;
    min-height: 64px;
    outline: none;
    padding: 10px;
    width: 100%;
}

.textarea-length {
    bottom: 10px;
    position: absolute;
    right: 10px;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, .50);
    letter-spacing: 0;
    line-height: 12px;
}

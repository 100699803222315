.textToSpeech {
    width: 1200px;
    height: 350px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    position: relative;
}
.textToSpeech_hint_type {
    width: 1200px;
    height: 350px;
    background: #FFFFFF;
    border: 1px solid #E64552;
    border-radius: 8px;
    position: relative;

}
    .textToSpeech_choose {
        width: 100%;
        height: 58px;
        background: rgba(212,216,251,.2);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
           display: flex;
           justify-content: space-between;
           align-items: center;
           .textToSpeech_choose_btnList {
               height: 100%;
               padding-left: 19px;
               box-sizing: border-box;
               display: flex;
               justify-content: space-between;
               align-items: center;
            .textToSpeech_choose_btnList {
                margin-left: 19px;
            };
            .textToSpeech_choose_chinese_btn {
                width: 80px;
                height: 30px;
                background: rgba(41, 50, 225,.2);
                // border: 1px solid transparent;
                border-radius: 15px;
                margin-right: 30px;
                span {
                    width: 80px;
                    height: 30px;
                    display: inline-block;
                    font-family: PingFangSC-Medium;
                    font-size: 14px;
                    color: #2932E1;
                    line-height: 30px;
                    letter-spacing: 0;
                    text-align: center;
                    font-weight: 500;
                };
            };
            .textToSpeech_choose_chinese_btn {
              cursor: pointer;
            };
            .textToSpeech_choose_english_btn {
                // background: rgba(212,216,251,.2);
                // border: 1px solid transparent;
                width: 80px;
                // border: none;
                height: 30px;
                background: transparent;
                line-height: 30px;
                margin-right: 30px;
                border-radius: 15px;
                span {
                   width: 80px;
                    height: 30px;
                    display: inline-block;
                    font-family: 
                    PingFangSC-Regular;
                    text-align: center;
                    font-size: 14px;
                    color: #333333;
                    letter-spacing: 0;
                    line-height: 30px;
                    font-weight: 400;
                    border: none;
                };
            };
            .textToSpeech_choose_english_btn:hover  {
                border: none;
                cursor: pointer;
            };
            .btn_spacing {
                  margin-right: 30px;
            }
           } 
        
        .textToSpeech_choose_random {
            height: 30px;
            font-family:
            PingFangSC-Regular;
            font-size: 14px;
            color: #2933E0;
            line-height: 30px;
            font-weight: 400;
            margin-right: 20px;
        };
        .textToSpeech_choose_random:hover {
            cursor: pointer;
        }
    };
    .textToSpeech_content_show {
        width: 100%;
        height: 230px;
        // border: 1px solid #E0E0E0;
        .textToSpeech_content_show_text {
            width: 100%;
            height: 230px;
            padding: 0px 19px 8px 0px;
            box-sizing: border-box;
            .ant-input {
                height: 208px;
                resize: none;
                // margin-bottom: 230px;
                padding: 21px 20px;
            };
        };
    };
    .textToSpeech_action {
        width: 100%;
        height: 60px;
        background: rgba(212,216,251,.2);
        // border-radius: 77px;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .textToSpeech_action_btn_box {
            // width: 216px;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 18px;
            .textToSpeech_action_btn {
                width: 100px;
                height: 40px;
                background: #2932E1;
                border-radius: 4px;
                font-family: PingFangSC-Medium;
                border: 1px solid transparent;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                font-weight: 500;
                
            };
            .textToSpeech_action_btn_a {
                width: 100px;
                display: block;
                text-align: center;
                height: 40px;
                background: #2932E1;
                border: 1px solid transparent;
                border-radius: 4px;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #FFFFFF;
                font-weight: 500;
                line-height: 40px;
                margin-right: 16px;
            };
            .textToSpeech_action_btn_loading {
                width: 100px;
                display: block;
                text-align: center;
                height: 40px;
                border: 1px solid #2932E1;
                border-radius: 4px;
                font-family: PingFangSC-Medium;
                font-size: 14px;
                color: #2932E1;
                font-weight: 500;
                // line-height: 40px;
                margin-right: 16px;
            };
        };
       
    };
    .textToSpeech_mask {
        width: 1200px;
        height: 350px;
        background: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // display: none;
    };
    .textToSpeech_mask_disable {
        width: 1200px;
        height: 289px;
        background: #FFFFFF;
        border: 1px solid transparent;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // display: none;
    };
    .detectionUrl_loading {
        width: 100%;
        height: 60px;;
        display: flex;
        
        border: 1px solid #E0E0E0;
        justify-content: space-between;
        align-items: center;
        padding: 0px 19px 0px 20px;
        box-sizing: border-box;

    };
    .detectionUrl {
        width: 100%;
        height: 60px;;
        display: flex;
        justify-content: space-between;
        padding: 15px 19px 13px 19px;
        box-sizing: border-box;
        border: 1px solid #E0E0E0;
        border-left: none;
        border-right: none;
        
    };
    .detectionUrl_text_link_box {
        // width: 1071px;
        // padding: 15px 0px 15px 12px;
        box-sizing: border-box;
        height: 32px;
        background: #FFFFFF;
        // border: 1px solid #E0E0E0;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: -0px;
        line-height: 22px;
        font-weight: 400;
        border-right:none 
    }
    .detectionUrl_text_link_box:hover{
         // width: 1071px;
        // padding: 15px 0px 15px 12px;
        box-sizing: border-box;
        height: 32px;
        background: #FFFFFF;
        border: none;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: -0px;
        line-height: 22px;
        font-weight: 400;
        border-right:none 
    } 
    .detectionUrl_text_link {
        width: 1071px;
        padding: 5px 12px;
        box-sizing: border-box;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: -0px;
        line-height: 22px;
        font-weight: 400;
        border-right:none ;
    };
    .detectionUrl_text_link:hover {
        // padding: 15px 0px 15px 12px;
        box-sizing: border-box;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        letter-spacing: -0px;
        line-height: 22px;
        font-weight: 400;
        border-right:none ;
    };
    .btn_url {
        width: 88px;
        height: 32px;
        text-align: center;
        font-family: 
        PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        line-height: 32px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
       
        display: flex;
        justify-content: center;
        align-items: center;
    };
    .btn_url_loading {
        width: 88px;
        height: 32px;
        text-align: center;
        font-family: 
        PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        line-height: 32px;
        background: #FFFFFF;
        border: 1px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn_url_loading:hover {
        cursor:pointer;
    };
    .btn_url:hover {
        cursor: pointer;
    };
    .btn_url_img {
        width: 16px;
        height: 16px;
        background: url("../../../../image/重新识别默认态.svg") no-repeat;
        background-size:cover; 
        margin-right: 8px;
    };

.textToSpeech_text {
    
    width: 100%;
    height: 231px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #333333;
    // line-height: 22px;
    font-weight: 400;
    padding: 29px 20px;
    box-sizing: border-box;
    position: relative;
    .textToSpeech_text_hint_box {
        width: 100%;
        height: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 20px;
        right: 20px;
        .voice_usedToIntroduce_context_title_context_top__box {
            position: relative;
            width: 14px;
            height: 14px;
            .voice_usedToIntroduce_context_title_context_top_box_img {
                width: 14px;
                height: 14px;
                background: url("../../../../image/复制默认态.svg") no-repeat;
                background-size:contain; 
                .voice_usedToIntroduce_context_title_context_top_img_hint {
                    width: 72px;
                    height: 34px;
                    background: #000000;
                    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
                    // height: 20px;
                    font-family: PingFangSC-Regular;
                    font-size: 12px;
                    color: #FFFFFF;
                    line-height: 36px;
                    font-weight: 400;
                    position: absolute;
                    top: -40px;
                    left: -40px;
                    border-radius: 6px;
                    text-align: center;
                    border:1px solid rgba(0,0,0.2);
                };
                .voice_usedToIntroduce_context_title_context_top_img_hint::before {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 33px;
                    left: 32px;
                    border: 3px solid #000000;
                    border-style: solid dashed dashed;
                    border-color: #000000 transparent transparent ;
                };
            };
            .voice_usedToIntroduce_context_title_context_top_img:hover {
                width: 14px;
                height: 14px;
                background: url("../../../../image/复制hover态.svg") no-repeat;
                background-size:cover;  
            };
        };
    };
   
}
@primary-color: #2932E1;
.audio-container {
    // margin: 0 auto;
    width: 841.5px;
    // overflow: hidden;
    display: flex;
    align-items: center;
    // position: relative;
    margin-left: 22px;
};
.audio-container_audio {
    // margin: 0 auto;
    width: 1015.5px;
    // overflow: hidden;
    display: flex;
    align-items: center;
    // position: relative;
    margin-left: 1px;
    margin-right: 31.5px;
};
.audio-toggle {
    border: 0; 
    outline: 0; 
    padding: 0;
    width: 32px;
    height: 32px;
    // margin: 0 28px; 
    background: none; 
    display: inline-block; 
    vertical-align: middle;
};
// 进度条底部
.audio-progress-bar-bg {
    width: 700px;
    height: 6px;
    background: #E5E5E5;
    border-radius: 4px;
    position: relative;
    margin-left:8px; 
    display: flex;
};
.audio-progress-bar-bg_audio {
    width: 910px;
    height: 6px;
    background: #E5E5E5;
    border-radius: 4px;
    position: relative;
    margin-left:8px; 
    display: flex;
};
//进度条
.audio-progress-bar {
    width: 0px;
    height: 6px;
    background: #2932E1;
    border-radius: 4px 0px 0px 4px;
   
}
// 拖拽
.progressDot {
    width: 18px;
    height: 18px;
    background: #2932E1;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15);
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(0%,-50%);
    // z-index: 2;
}
.audio-time {
    width: 91px;
    height: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666666;
    text-align: center;
    font-weight: 400;
    margin-left: 8.5px;
    line-height: 20px;
}
.icon-play{
    width: 32px;
    height: 32px;
    background: url("../../../../image/播放.svg") no-repeat; 
    display: block; 
    // color: #478f59;
    };
.icon-pause{
    width: 32px;
    height: 32px; 
    background: url("../../../../image/暂停.svg") no-repeat; 
    display: block; 
    // color: #478f59;
};

@primary-color: #2932E1;
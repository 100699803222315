/**
 * @file paddlehub demo 词法分析模型信息样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.lexical-model-info {
    font-weight: 400;
    margin-top: 10px;

    .ant-row {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    label,
    .ant-form-item-control {
        font-size: 16px;
        color: #000;
        letter-spacing: 0;
        line-height: 26px;
    }

    .ant-form-item-control {
        line-height: 40px;
    }

    & &-select-model {
        min-width: 476px;
    }

    &-devresource-item {
        border-right: 1px solid @primary-color;
        padding: 0 8px;

        &:last-of-type {
            border-right: 0;
        }

        &-disabled,
        &-disabled:hover {
            color: #cecece;
        }
    }
}

/**
 * @file paddlehub demo 新冠肺炎医疗影像 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import './components/lung-chart/style';
@import './components/textarea/style';
@import './components/upload-btn/style';
@import './components/main-img/style';
@import './components/lesion-table/style';
@import './components/message-success/style';
@import './components/medical-img-modal/style';

.medical-img {
    background-color: #233e68;
    background-image: linear-gradient(0deg, #233e68 2%, #0a1431 100%);
    color: #fff;
    margin-top: 30px;
    position: relative;

    &-menu {
        border-bottom: .5px solid rgba(255, 255, 255, .3);
        color: #fff;
        display: block;
        font-weight: 500;
        font-size: 26px;
        list-style-type: none;
        line-height: 1;
        margin: 0;
        overflow: hidden;
        padding: 0 15px;
    }

    &-menu-item {
        display: inline-block;
        float: left;
        padding: 25px 15px;

        &-disabled {
            color: rgba(255, 255, 255, 0.20);
            font-weight: 400;
        }
    }

    &-body {
        display: flex;
    }

    &-left {
        padding: 25px 30px;
        width: 440px;
    }

    &-right {
        border-left: .5px solid rgba(255, 255, 255, .3);
        flex-grow: 1;
        padding: 25px 30px;
    }

    &-h2 {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;
        margin: 30px 0 18px 0;
        text-align: left;

        &-sub {
            color: rgba(255, 255, 255, .50);
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 1;
            margin-left: 8px;
        }
    }

    &-right-title {
        color: #fff;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 30px;

        &::before {
            background: #0057ff;
            content: '';
            display: inline-block;
            height: 18px;
            margin-right: 8px;
            vertical-align: top;
            width: 3px;
        }
    }

    &-chart-text {
        color: #fff;
        float: right;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1;
        margin-right: 160px;
        margin-top: 4px;
    }

    &-chart-wrap {
        margin: -34px -20px 0 -20px;
    }

    &-chart {
        &-body-left {
            display: inline-block;
            width: 50%;
        }
    }

    &-doctor-advice-textarea {
    }
}

@primary-color: #2932E1;
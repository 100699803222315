.p-x-ai-local-download {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 60px;
}
.p-x-ai-local-download::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-local-download-title {
  height: 42px;
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}
.p-x-ai-local-download-tips {
  height: 48px;
  line-height: 48px;
  padding: 0px 20px;
  box-sizing: border-box;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  background: rgba(41, 50, 225, 0.05);
  border: 1px solid #e4e7fd;
  border-radius: 8px;
  margin-bottom: 40px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules {
  display: flex;
  flex-wrap: wrap;
}
.p-x-ai-local-download .p-x-ai-local-download-modules::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module {
  width: 584px;
  min-height: 460px;
  background-image: linear-gradient(180deg, rgba(68, 76, 240, 0.05) 1%, rgba(41, 50, 225, 0) 55%);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 60px 20px 60px 80px;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-right: 32px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module:nth-of-type(2),
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module:nth-of-type(4),
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module:nth-of-type(6) {
  margin-right: 0px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module-header-img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  display: block;
  margin-right: 10px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module-header-title {
  height: 28px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 500;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-desc {
  margin-bottom: 40px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-desc::after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  height: 0;
  clear: both;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-desc-content {
  display: flex;
  margin-bottom: 12px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-desc-content-title {
  height: 28px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 400;
  margin-right: 8px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-desc-content-txt {
  height: 28px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  text-align: justify;
  line-height: 28px;
  font-weight: 500;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download .ant-btn-primary:focus {
  color: #FFFFFF;
  background: #1823BA !important;
  border-color: #1823BA !important;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download-btn {
  margin-right: 16px;
  background: #2932E1;
  width: 140px;
  height: 40px;
  border-radius: 20px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download-btn span {
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download-btn:hover {
  background: #5156ED;
  border: 1px solid #5156ed;
  border-radius: 20px;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download-btn:disabled {
  background: #C5D0FF;
  border: none;
}
.p-x-ai-local-download .p-x-ai-local-download-modules .p-x-ai-local-download-modules-module .p-x-ai-local-download-modules-module-download a {
  display: inline-block;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 16px;
  font-weight: 500;
}

/**
 * @file paddlehub demo 口罩识别样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.mask-detect {
    margin-top: 22px;
    position: relative;

    &-right-background {
        background: #fafafa;
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 360px;
        z-index: -1;
    }

    &-right {
        float: right;
        width: 360px;
    }

    &-main {
        margin-right: 360px;
    }

    &-img {
        background-color: #343434;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 524px;
        position: relative;
    }

    &-img-input {
        background-color: rgba(0, 0, 0, .5);
        bottom: 0;
        left: 0;
        padding: 20px;
        position: absolute;
        right: 0;
        text-align: right;

        &-info {
            color: #ccc;
            float: left;
            font-size: 11.8px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 38px;
        }

        &-upload {
            float: right;
        }
    }

    &-img-gallery {
        background: #ebebeb;
        height: 130px;
        padding: 20px 10px;
        overflow-x: auto;
        overflow-y: hidden;

        &-item-wrap {
            display: inline-block;
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }

        &-item {
            background-color: #c0c0c0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: inline-block;
            height: 90px;
            margin: 0 10px;
            cursor: pointer;
            width: 144px;
        }

        &-item-selected {
            border: 2px solid #2932e1;
        }
    }


    &-json {
        &-title {
            border-bottom: 1px solid #e1e1e1;
            border-top: 1px solid #e1e1e1;
            color: #333;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            padding: 15px 20px;
        }

        &-body {
            height: 606px;
            padding: 20px;
            overflow: auto;
        }

        &-body-content {
            background: inherit;
            display: inline-block;
            margin: 0;
            padding: 0;
            overflow: visible;
        }
    }
}

@primary-color: #2932E1;